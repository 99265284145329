import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  Divider,
  FormLabel,
  Grid,
  HStack,
  Heading,
  Image,
  Input,
  Stack,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import * as Scroll from 'react-scroll';
import React, { useEffect, useState } from 'react';
import { GiAutoRepair, GiSolarPower } from 'react-icons/gi';
import { MdBattery20 } from 'react-icons/md';
import { IoIosConstruct, IoIosSettings } from 'react-icons/io';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { CgBolt } from 'react-icons/cg';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { contactUs } from '../redux/actions/user';
import { toast } from 'react-hot-toast';
import { ImWhatsapp } from 'react-icons/im';
import Calculator from './Calculator';

const Home = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const dispatch = useDispatch();
  const {
    loading,
    message: stateMessage,
    error,
  } = useSelector(state => state.user);

  const submitHandler = e => {
    e.preventDefault();
    dispatch(contactUs(name, email, message, subject));
    setName('');
    setEmail('');
    setMessage('');
    setSubject('');
  };
  // // useEffect(() => {
  // //   window.scrollTo(0, 0);
  // // }, []);

  const [tab, setTab] = useState(1);
  const services = [
    {
      id: '01',
      title: 'Energy Efficiency Consultation',
      heading:
        'Navigate towards energy-efficient horizons with personalized Energy Efficiency Consultation from  Jain Shree Solar, your partner in sustainable transformation.',
      description:
        'Customer satisfaction is our top priority and we offer Energy Efficiency Consultation work to one and all. This includes Energy Efficiency Consultation services for commercial and residential job sites. Our customers know that once they decide to work with Jain Shree Solar we will take care of them all the way down the line. Contact us today to see how we can help.',
      img: 'https://res.cloudinary.com/dmozsgywz/image/upload/v1697365210/Solar-Power/service1_tetsfb.jpg',
      icon: <CgBolt color="blue" />,
    },
    {
      id: '02',
      title: 'Solar Panel Installation',
      heading:
        'Experience the brilliance of solar energy with seamless solar panel installations by Jain Shree Solar, illuminating your path to a sustainable future.',
      description:
        'Our staff at Jain Shree Solar are ready to help you with any Solar Panel Installation or any other services you may require. We do everything we can to be your one-stop-shop, so if you want to find out more about our Solar Panel Installation get in touch with a member of our team today, and we will be sure to assist you. Just give us a call.',
      img: 'https://res.cloudinary.com/dmozsgywz/image/upload/v1694361036/Solar-Power/service2_m5yx8t.jpg',
      icon: <GiSolarPower color="orange" />,
    },
    {
      id: '03',
      title: 'Solar Panel Repair',
      heading:
        'Revive your solar power potential with swift and expert solar panel repair services by Jain Shree Solar, ensuring your renewable energy journey remains uninterrupted.',
      description:
        'Jain Shree Solar started offering our expertise in Solar Panel Repair shortly after opening for business back in 2000. We realised that with all our years of expertise and all our equipment, we could offer our clients the most professional Solar Panel Repair work in town at amazing value for money. If you have a project that needs working on contact us to see how we can be of service today.',
      img: 'https://res.cloudinary.com/dmozsgywz/image/upload/v1694361050/Solar-Power/service3_ztpmep.jpg',
      icon: <GiAutoRepair color="grey" />,
    },
    {
      id: '04',
      title: 'Engineering Procurement & Construction',
      heading:
        'Empower your projects with end-to-end Engineering, Procurement, and Construction expertise from Jain Shree Solar, turning concepts into reality.',
      description:
        'Customer satisfaction is our top priority and we offer Energy Efficiency Consultation work to one and all. This includes Energy Efficiency Consultation services for commercial and residential job sites. Our customers know that once they decide to work with Solar India we will take care of them all the way down the line. Contact us today to see how we can help.',
      img: 'https://res.cloudinary.com/dmozsgywz/image/upload/v1697365283/Solar-Power/service4_gld7jk.jpg',
      icon: <IoIosConstruct color="black" />,
    },
  ];
  const products = [
    {
      id: '01',
      title: 'SOLAR PV ROOFTOPS',

      img: 'solar_pv_rooftop.png',
    },
    {
      id: '02',
      title: 'Solar Water Heater',

      img: 'solar_water_heater.png',
    },
    {
      id: '03',
      title: 'Solar Inverters ',

      img: 'solar_inverter.png',
    },
    {
      id: '04',
      title: 'Solar BOS kit',

      img: 'solar_boss_kit.png',
    },
    {
      id: '05',
      title: 'Solar Street Lights',

      img: 'solar_street_light.png',
    },
    {
      id: '06',
      title: 'Solar Irrigation Pump',

      img: 'solar_irrigation_pump.png',
    },
    {
      id: '07',
      title: 'Solar Panels',
      img: 'solarpanel.png',
    },
    {
      id: '08',
      title: 'Solar Cool Cap',

      img: 'solar_cool_cap.png',
    },
    {
      id: '09',
      title: 'Solar Cooker',

      img: 'solar_cooker.png',
    },
    {
      id: '10',
      title: 'Wind Farms',

      img: 'wind_farm.png',
    },
    {
      id: '11',
      title: 'Solar Garden Lights',

      img: 'solar_gardern_light.png',
    },
  ];

  const projects = [
    {
      id: '01',
      title: 'Residential Solar Installations',
      description:
        'Designing and installing solar energy systems for homes to help homeowners generate their own clean energy and reduce electricity bills.',
    },
    {
      id: '02',
      title: 'Commercial Solar Solutions',
      description:
        'Developing solar power systems for businesses and commercial properties, enabling them to cut operational costs and demonstrate their commitment to sustainability.',
    },
    {
      id: '03',
      title: 'Solar Farms and Power Plants',
      description:
        'Creating large-scale solar farms or power plants to generate significant amounts of renewable energy for distribution into the grid.',
    },
    {
      id: '04',
      title: 'Off-Grid Solar Systems',
      description:
        'Designing and installing solar systems for remote areas or locations with limited access to traditional power sources, providing reliable electricity for essential needs.',
    },
    {
      id: '05',
      title: 'Solar-Powered Water Pumping',
      description:
        'mplementing solar-powered water pumping systems for agricultural irrigation, livestock watering, and remote water supply projects.',
    },
    {
      id: '06',
      title: 'Solar-Powered Telecom Towers',
      description:
        'Developing solar solutions for telecom towers in rural or off-grid areas, ensuring uninterrupted communication services while minimizing environmental impact.',
    },
    {
      id: '07',
      title: 'Solar-Powered Street Lighting',
      description:
        'Installing solar-powered LED street lighting systems to enhance public safety while reducing energy costs and environmental impact.',
    },
    {
      id: '08',
      title: 'Solar-Powered Desalination',
      description:
        'Creating solar-powered desalination plants to convert seawater into fresh drinking water in regions facing water scarcity.',
    },
    {
      id: '09',
      title: 'Solar Retrofitting',
      description:
        'Upgrading existing structures with solar panels, energy storage, and efficient lighting systems to enhance energy efficiency and reduce carbon footprint.',
    },
  ];
  const whyUs = [
    {
      id: '01',
      title: 'Solar Savings',
      description:
        'Jain Shree Solar provides the most competitive pricing for solar arrays, featuring special offers and partnerships with top-tier financing providers nationwide. By choosing us, you can attain energy independence while enjoying substantial monthly savings on your energy bills.',
      img: 'https://res.cloudinary.com/dmozsgywz/image/upload/v1694361075/Solar-Power/solar_savings_gi6yqc.png',
    },
    {
      id: '02',
      title: 'Simple Solutions',
      description:
        "At Jain Shree Solar, we handle all aspects of your tailored solar solution, from surveys and design to financing, installation, monitoring, and long-term warranties. We're dedicated to being your partner for the long run, ensuring your solar investment stays dependable and efficient.",
      img: 'https://res.cloudinary.com/dmozsgywz/image/upload/v1694361066/Solar-Power/simple_solutions_zxk5an.png',
    },
    {
      id: '03',
      title: 'Superior Service',
      description:
        'Jain Shree Solar offers a seamless, fully in-house process, combining top-tier technology, expert craftsmanship, and strong warranties. Our 24/7 team is deeply committed to your satisfaction, making your happiness our primary goal on your solar journey. Trust us for excellence in solar solutions.',
      img: 'https://res.cloudinary.com/dmozsgywz/image/upload/v1694361077/Solar-Power/superior_service_ysfxli.png',
    },
  ];
  const serviceHandler = id => {
    setTab(id);
    // window.scrollTo(0, 0);
    Scroll.scroller.scrollTo('myScrollToElement', {
      duration: 1000,
      delay: 100,
      smooth: true,
      // containerId: 'ContainerElementID',
      offset: -60,
    });
  };
  // var Scroll = require('react-scroll');
  // var scroll = Scroll.animateScroll;

  // scroll.scrollTo(0, 0);
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);
  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch({ type: 'clearError' });
    }
    if (stateMessage) {
      toast.success(stateMessage);
      dispatch({ type: 'clearMessage' });
    }
  }, [dispatch, error, stateMessage]);
  return (
    <>
      <VStack>
        <Grid
          gridTemplateColumns={{ base: '1fr', lg: '1fr 1fr' }}
          width={{ base: '100%', md: '100%', lg: '90%' }}
          // borderRadius={'20px'}
          alignItems={'center'}
          gap={['10px', '30px']}
          padding={['5px', '30px']}
          // backgroundColor={'green.100'}
          // border={'2px solid red'}
        >
          <VStack
            justifyContent={'center'}
            alignItems={'left'}
            height={'100%'}
            // border={'2px solid red'}
            gap={'15px'}
            padding={'10%'}
          >
            <Heading
              children={'We Invest Energy for better future'}
              width={'100%'}
              color={'green'}
              fontSize={['26px', '40px']}
            />
            <Text width={'100%'} color={'black'}>
              We Invest in the future of planetand explore our range of services
              and get started by calling us today!
            </Text>
            <Text
              fontSize={'17px'}
              fontWeight={'medium'}
              color={'#001861'}
              marginTop={'10px'}
            >
              Don’t know where to start? We’ll show you how!
            </Text>
            <Link to={'/contact'}>
              <Button
                colorScheme="orange"
                width={'fit-content'}
                transform={'auto'}
                _hover={{ scale: '1.1' }}
              >
                Get a Quote
              </Button>
            </Link>
          </VStack>
          <Box>
            <Image
              src="https://res.cloudinary.com/dmozsgywz/image/upload/v1694361094/Solar-Power/hero_wk7s43.png"
              borderRadius={'10px'}
              width={'100%'}
              // boxShadow={'bas e'}
            />
          </Box>
        </Grid>

        <VStack
          height={{ base: '250px', md: '400px', lg: '500px' }}
          width={'100%'}
          position={'relative'}
          justifyContent={'center'}
          p="6"
        >
          <Image
            zIndex={'-1'}
            position={'absolute'}
            src="https://res.cloudinary.com/dmozsgywz/image/upload/v1694361090/Solar-Power/hero1_dgb9vb.jpg"
            height={'100%'}
            width={'100%'}
            // borderRadius={['5px', '20px']}
            filter={'auto'}
            brightness={'75%'}
          />
          {/* <Heading
            color={'orange'}
            children="About Us"
            position={'absolute'}
            top={'5%'}
            left={['30%', '7%']}
            fontSize={'30px'}
          /> */}

          <VStack width={{ base: '90%', md: '80%', lg: '70%' }}>
            <Heading
              children={'Solar Energy: Your Brighter Tomorrow'}
              color={'white'}
              fontSize={{ base: '24px', md: '28px', lg: '30px' }}
            />
            <Text
              color={'white'}
              textAlign={'center'}
              display={{ base: 'none', md: 'none', lg: 'block' }}
            >
              Jain Shree Solar: Your premier source for high-quality solar
              equipment in Indore, Ujjain and etc. Explore our website for all
              your construction and industrial needs. Join us in harnessing
              clean energy for a brighter future.
            </Text>
          </VStack>
        </VStack>
        <Text
          display={{ base: 'block', md: 'block', lg: 'none' }}
          boxShadow="base"
          p="6"
          rounded="md"
          bg="white"
        >
          Jain Shree Solar: Your premier source for high-quality solar equipment
          in Indore and Ujjain and etc. Explore our website for all your
          construction and industrial needs. Join us in harnessing clean energy
          for a brighter future.
        </Text>

        <Grid
          gridTemplateColumns={['1fr', '1fr 1fr']}
          width={{ base: '100%', md: '98%', lg: '75%' }}
          padding={'10px 30px'}
          gap={['10px', '50px']}
          alignItems={'end'}
          // border={'2px solid red'}
        >
          <VStack alignItems={['center', 'left']}>
            <Heading
              children="Our Services"
              fontSize={'30px'}
              color={'orange'}
            />
            <Heading
              children="We Drive The Transition To Reliable Energy "
              fontSize={'20px'}
              textAlign={'center'}
              width={{ base: '98%', md: '90%', lg: '80%' }}
            />
          </VStack>
          <Text>
            We drive the transition to more sustainable, reliable & affordable
            energy systems. With our innovative technologies, we energize
            society!
          </Text>
        </Grid>

        <Grid
          gridTemplateColumns={{ base: '1fr', md: '1fr', lg: '1fr 1fr' }}
          width={{ base: '100%', md: '98%', lg: '90%' }}
          padding={{ base: '10px', md: '10px', lg: '10px 30px' }}
          gap={{ base: '10px', md: '15px', lg: '30px' }}
          alignItems={'center'}
          // border={'2px solid red'}
        >
          <VStack
            width={'100%'}
            gap={['15px', '15px']}
            padding={'10px'}
            // border={'2px solid red'}
          >
            {services.map((item, index) => (
              <>
                <HStack
                  display={{ base: 'flex', md: 'flex', lg: 'none' }}
                  width={'100%'}
                  alignItems={'flex-start'}
                  boxShadow="md"
                  p="3"
                  rounded="md"
                  bg={`${tab === index + 1 ? 'green.200' : 'white'}`}
                  onClick={() => serviceHandler(index + 1)}
                  transition={'0.3s'}
                  cursor={'pointer'}
                  // _hover={{ bg: 'blackAlpha.100' }}
                >
                  <Box fontSize={'50px'}>{item.icon}</Box>
                  <VStack alignItems={'left'}>
                    <Scroll.Element name="myScrollToElement"></Scroll.Element>
                    <Heading children={item.title} fontSize={'18px'} />

                    <Button
                      variant={'unstyled'}
                      width={'fit-content'}
                      color={'green'}
                    >
                      {' '}
                      Learn More <ArrowForwardIcon />
                    </Button>
                  </VStack>
                </HStack>
                <HStack
                  // border={'2px solid red'}
                  display={{ base: 'none', md: 'none', lg: 'flex' }}
                  width={'100%'}
                  alignItems={'flex-start'}
                  boxShadow="md"
                  p="3"
                  rounded="md"
                  bg={`${tab === index + 1 ? 'green.200' : 'white'}`}
                  onClick={() => setTab(index + 1)}
                  transition={'0.1s'}
                  cursor={'pointer'}
                  _hover={{
                    bg: `${tab === index + 1 ? 'green.200' : 'blackAlpha.100'}`,
                  }}
                >
                  <Box fontSize={'50px'}>{item.icon}</Box>
                  <VStack alignItems={'left'}>
                    <Heading children={item.title} fontSize={'21px'} />
                    {/* <Text>{item.heading}</Text> */}
                    <Button
                      variant={'unstyled'}
                      width={'fit-content'}
                      color={'green'}
                    >
                      {' '}
                      Learn More <ArrowForwardIcon />
                    </Button>
                  </VStack>
                </HStack>
              </>
            ))}
          </VStack>

          <VStack alignItems={'left'} gap={'10px'}>
            {services.map((item, index) => (
              <>
                {tab === index + 1 && (
                  <>
                    <Scroll.Element name="myScrollToElement"></Scroll.Element>
                    <Heading
                      children={item.title}
                      fontSize={['20px', '24px']}
                    />
                    <Box>
                      <Image
                        src={item.img}
                        width={'100%'}
                        height={{ base: '250px', md: '400px', lg: '300px' }}
                        borderRadius={'10px'}
                      />
                    </Box>
                    <Text>{item.heading}</Text>
                    <Button
                      colorScheme="orange"
                      transform={'auto'}
                      width={'fit-content'}
                      _hover={{ scale: '1.1' }}
                    >
                      <Link to={'/contact'}>
                        Get a Quote <ArrowForwardIcon />
                      </Link>
                    </Button>
                  </>
                )}
              </>
            ))}
          </VStack>
        </Grid>

        <VStack
          width={{ base: '95%', md: '99%', lg: '88%' }}
          padding={{ base: '5px', md: '5px', lg: '10px' }}
          alignItems={'left'}
          // border={'2px solid red'}
        >
          <Heading
            children="Reasons why choose Us"
            color={'orange'}
            fontSize={'30px'}
            padding={['10px', '0px 20px']}
          />
          <Grid
            padding={{ base: '10px', md: '5px', lg: '10px' }}
            gap={{ base: '20px', md: '5px', lg: '30px' }}
            gridTemplateColumns={{
              base: '1fr',
              md: '1fr 1fr',
              lg: '1fr 1fr 1fr',
            }}
          >
            {whyUs.map((item, index) => (
              <VStack
                padding={{ base: '20px', md: '5px', lg: '30px' }}
                // border={'2px solid green'}
              >
                <Heading children={item.title} fontSize={'20px'} />
                <Image src={item.img} width={'50%'} />
                <Text textAlign={'center'}>{item.description}</Text>
              </VStack>
            ))}
          </Grid>
        </VStack>

        <Box width={'100%'}>
          <Calculator />
        </Box>

        <Grid
          gridTemplateColumns={['1fr', '1fr 1fr']}
          marginBottom={'30px'}
          padding={'10px'}
          // alignItems={'center'}
          // border={'2px solid red'}
        >
          <VStack padding={'5px'}>
            <Image
              src="https://res.cloudinary.com/dmozsgywz/image/upload/v1694596456/Solar-Power/contact_ux9v7v.jpg"
              borderRadius={'10px'}
              height={{ base: '100%', md: '90%', lg: '70%' }}
              width={{ base: '100%', md: '100%', lg: '80%' }}
            />
          </VStack>
          <VStack alignItems={'left'} padding={'0px 20px'}>
            <Heading children="Message Us" fontSize={'30px'} color={'orange'} />
            <Text>
              Got questions or feedback? Reach out to us, and we'll get back to
              you ASAP!
            </Text>
            <form onSubmit={submitHandler} style={{ width: '100%' }}>
              <Box my={'3'}>
                <Input
                  required
                  id="name"
                  value={name}
                  onChange={e => setName(e.target.value)}
                  placeholder="Full Name"
                  type={'text'}
                  focusBorderColor="green.500"
                  backgroundColor={'white'}
                  height={'50px'}
                />
              </Box>
              <Box my={'3'}>
                <Input
                  required
                  id="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  placeholder="Email Address"
                  type={'email'}
                  focusBorderColor="green.500"
                  backgroundColor={'white'}
                  height={'50px'}
                />
              </Box>
              <Box my={'3'}>
                <Input
                  required
                  id="subject"
                  value={subject}
                  onChange={e => setSubject(e.target.value)}
                  placeholder="Subject"
                  type={'text'}
                  focusBorderColor="green.500"
                  backgroundColor={'white'}
                  height={'50px'}
                />
              </Box>
              <Box my={'3'}>
                <Textarea
                  required
                  id="message"
                  value={message}
                  onChange={e => setMessage(e.target.value)}
                  placeholder="Write your message here...."
                  focusBorderColor="green.500"
                  backgroundColor={'white'}
                  height={'100px'}
                />
              </Box>

              <Button isLoading={loading} colorScheme={'green'} type="submit">
                Send Message
              </Button>
            </form>
          </VStack>
        </Grid>
      </VStack>
    </>
  );
};

export default Home;
