import {
  Box,
  Button,
  Grid,
  HStack,
  Heading,
  Image,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const Projects = () => {
  const projects = [
    {
      title: 'Neemuch Industrial Area Solar Power Plant',
      location: 'Neemuch Industrial Area, Madhya Pradesh, India',
      overview:
        'Our team at Jain Shree Solar takes immense pride in showcasing one of our standout projects, the Neemuch Industrial Area Solar Power Plant. This industrial-scale solar installation, located in the heart of Madhya Pradesh, demonstrates our commitment to sustainable energy solutions.',
      img: 'https://res.cloudinary.com/dmozsgywz/image/upload/v1694361074/Solar-Power/project-2-img-2_fue5td.jpg',
    },
    {
      title:
        'Solar Water Heating Systems for Kanya Shiksha Parisar Girls Hostel',
      location:
        'Kanya Shiksha Parisar Girls Hostel- Maheshwar, Barwani, Rajpur, and Sendhwa',
      overview:
        'We are delighted to present our successful implementation of solar water heating systems at the KanyaShikshaParisar Girls Hostel, benefiting students and staff alike. This project exemplifies our commitment to harnessing renewable energy for practical and sustainable applications.',
      img: 'https://res.cloudinary.com/dmozsgywz/image/upload/v1694361046/Solar-Power/project-1-img-4_jtadyc.jpg',
    },
    {
      title: 'Solar Water Heating Systems for Government Girls Hostel ',
      location: 'Government Girls Hostel- Dhar, Dhamnod, and Tirla Towns',
      overview:
        'We take great pride in showcasing yet another successful project, the installation of 46 solar water heating systems at the Government Girls Hostel in Dhar, Dhamnod, and Tirla towns. This project underscores our unwavering commitment to harnessing solar energy for practical and sustainable applications.',
      img: 'https://res.cloudinary.com/dmozsgywz/image/upload/v1694361064/Solar-Power/project-3-img-2_nhxdyt.jpg',
    },
    {
      title: 'Sampat Farm Solar Power Generation Plant',
      location: 'Sampat Farm area of Indore, Madhya Pradesh',
      overview:
        'We are delighted to present our Sampat Farm Solar Power Generation Plant project, a testament to our commitment to sustainable energy solutions. Located in the serene surroundings of Sampat Farm in Indore, Madhya Pradesh, this 5 kW solar power plant has been custom-designed to meet the diverse energy needs of our esteemed client.',
      img: 'https://res.cloudinary.com/dmozsgywz/image/upload/v1694361088/Solar-Power/project-4-img-1_fqq8rq.jpg',
    },
  ];
  const typeOfProjects = [
    {
      id: '01',
      title: 'Residential Solar Installations',
      description:
        'Designing and installing solar energy systems for homes to help homeowners generate their own clean energy and reduce electricity bills.',
    },
    {
      id: '02',
      title: 'Commercial Solar Solutions',
      description:
        'Developing solar power systems for businesses and commercial properties, enabling them to cut operational costs and demonstrate their commitment to sustainability.',
    },
    {
      id: '03',
      title: 'Solar Farms and Power Plants',
      description:
        'Creating large-scale solar farms or power plants to generate significant amounts of renewable energy for distribution into the grid.',
    },
    {
      id: '04',
      title: 'Off-Grid Solar Systems',
      description:
        'Designing and installing solar systems for remote areas or locations with limited access to traditional power sources, providing reliable electricity for essential needs.',
    },
    {
      id: '05',
      title: 'Solar-Powered Water Pumping',
      description:
        'mplementing solar-powered water pumping systems for agricultural irrigation, livestock watering, and remote water supply projects.',
    },
    {
      id: '06',
      title: 'Solar-Powered Telecom Towers',
      description:
        'Developing solar solutions for telecom towers in rural or off-grid areas, ensuring uninterrupted communication services while minimizing environmental impact.',
    },
    {
      id: '07',
      title: 'Solar-Powered Street Lighting',
      description:
        'Installing solar-powered LED street lighting systems to enhance public safety while reducing energy costs and environmental impact.',
    },
    {
      id: '08',
      title: 'Solar-Powered Desalination',
      description:
        'Creating solar-powered desalination plants to convert seawater into fresh drinking water in regions facing water scarcity.',
    },
    {
      id: '09',
      title: 'Solar Retrofitting',
      description:
        'Upgrading existing structures with solar panels, energy storage, and efficient lighting systems to enhance energy efficiency and reduce carbon footprint.',
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <VStack>
        <VStack
          height={{ base: '250px', md: '400px', lg: '500px' }}
          width={'100%'}
          position={'relative'}
          justifyContent={'center'}
          p="6"
        >
          <Image
            zIndex={'-1'}
            position={'absolute'}
            src="https://res.cloudinary.com/dmozsgywz/image/upload/v1694361071/Solar-Power/projects_s0tazq.jpg"
            height={'100%'}
            width={'100%'}
            // borderRadius={['5px', '20px']}
            filter={'auto'}
            brightness={'60%'}
          />
          {/* <Heading
            color={'white'}
            children="About Us"
            position={'absolute'}
            top={'15%'}
            left={['30%', '7%']}
          /> */}

          <VStack width={{ base: '90%', md: '80%', lg: '70%' }}>
            <Heading
              children={'Sunshine Solutions: Harnessing Solar Energy'}
              color={'white'}
              fontSize={{ base: '24px', md: '28px', lg: '30px' }}
            />
            <Text
              color={'white'}
              textAlign={'center'}
              display={{ base: 'none', md: 'none', lg: 'block' }}
            >
              Experience the radiance of sustainable energy with Sunshine
              Solutions. We specialize in harnessing the power of the sun
              through cutting-edge solar technology. Our mission is to provide
              you with reliable and eco-friendly energy solutions that not only
              save you money but also contribute to a greener future. Join us in
              embracing solar energy for a brighter and cleaner tomorrow.
            </Text>
          </VStack>
        </VStack>
        <Text
          display={{ base: 'block', md: 'block', lg: 'none' }}
          boxShadow="base"
          p="6"
          rounded="md"
          bg="white"
        >
          Experience the radiance of sustainable energy with Sunshine Solutions.
          We specialize in harnessing the power of the sun through cutting-edge
          solar technology. Our mission is to provide you with reliable and
          eco-friendly energy solutions that not only save you money but also
          contribute to a greener future. Join us in embracing solar energy for
          a brighter and cleaner tomorrow.
        </Text>
        <VStack
          padding={{ base: '10px', md: '10px', lg: '40px' }}
          gap={'20px'}
          // border={'2px solid red'}
        >
          <Heading children="Our Projects" fontSize={'30px'} color={'orange'} />
          <VStack gap={'30px'}>
            {projects.map((item, index) => (
              <VStack
                key={index}
                gap={'10px'}
                boxShadow="md"
                padding={{ base: '5px', md: '5px', lg: '10px' }}
              >
                <Grid
                  alignItems={'center'}
                  gridTemplateColumns={{
                    base: '1fr',
                    md: '1fr 1fr',
                    lg: '2fr 2fr 1fr',
                  }}
                  gap={['20px', '30px']}
                  // padding={['5px', '20px']}
                  // alignItems={'center'}
                  padding={'10px'}
                  rounded="md"
                  bg="white"
                  // height={'300px'}
                  // border={'2px solid green'}
                >
                  <Image
                    // border={'2px solid green'}
                    src={item.img}
                    width={'100%'}
                    height={{ base: '200px', md: '250px', lg: '280px' }}
                    borderRadius={'10px'}
                  />

                  <VStack>
                    <Heading children={item.title} fontSize={'25px'} />
                    <Text>{item.overview}</Text>
                  </VStack>
                  <Stack
                    // width={'100%'}
                    alignItems={'center'}
                    justifyContent={['space-between', 'center']}
                    gap={['20px', '30px']}
                    direction={['row-reverse', 'column']}
                    display={{ base: 'flex', md: 'none', lg: 'flex' }}
                    // border={'2px solid red'}
                  >
                    <Text textAlign={'center'}>{item.location}</Text>
                    <Link to={`/project/${index + 1}`}>
                      <Button colorScheme="green" width={'fit-content'}>
                        View Project
                      </Button>
                    </Link>
                  </Stack>
                </Grid>
                <HStack
                  // alignItems={'left'}
                  margin={'10px'}
                  justifyContent={['space-between', 'center']}
                  gap={'30px'}
                  display={{ base: 'none', md: 'flex', lg: 'none' }}
                  width={'100%'}
                  // border={'2px solid red'}
                >
                  <Text
                    // border={'2px solid red'}
                    textAlign={'center'}
                    width={'100%'}
                    padding={'0px 20px'}
                  >
                    {item.location}
                  </Text>
                  <Link
                    to={`/project/${index + 1}`}
                    style={{
                      width: '100%',
                      textAlign: 'center',
                      // border: '2px solid red',
                    }}
                  >
                    <Button colorScheme="green" width={'fit-content'}>
                      View Project
                    </Button>
                  </Link>
                </HStack>
              </VStack>
            ))}
          </VStack>
        </VStack>
        <VStack
          width={'100%'}
          padding={{ base: '10px', md: '10px', lg: '20px' }}
        >
          <VStack
            alignItems={'left'}
            width={{ base: '98%', md: '98%', lg: '90%' }}
            padding={['5px', '10px 30px']}
          >
            <Heading
              children="Types of Solar Projects"
              fontSize={'30px'}
              color={'orange'}
            />
            <Text fontSize={{ base: '16px', md: '18px', lg: '20px' }}>
              Empowering Change Through Solar Excellence: Diverse Projects
              Driving Sustainability
            </Text>
          </VStack>
          <Grid
            gridTemplateColumns={{
              base: '1fr',
              md: '1fr 1fr',
              lg: '1fr 1fr 1fr',
            }}
            width={{ base: '98%', md: '98%', lg: '90%' }}
            gap={{ base: '20px', md: '20px', lg: '30px' }}
            padding={{ base: '5px', md: '10px', lg: '30px' }}
          >
            {typeOfProjects.map((item, index) => (
              <VStack
                id={index}
                boxShadow={'base'}
                padding={'20px'}
                backgroundColor={'whiteAlpha.400'}
                borderRadius={'20px'}
                alignItems={'left'}
                // border={'2px solid red'}
              >
                <Heading
                  children={item.title}
                  fontSize={'22px'}
                  color={'green'}
                />
                <Text>{item.description}</Text>
              </VStack>
            ))}
          </Grid>
        </VStack>
      </VStack>
    </>
  );
};

export default Projects;
