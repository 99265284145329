import {
  HStack,
  Heading,
  Image,
  Text,
  VStack,
  Button,
  Box,
  Stack,
  Grid,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const aboutUs = [
    {
      id: '01',
      img: 'https://res.cloudinary.com/dmozsgywz/image/upload/v1694361081/Solar-Power/about1_isoqtn.jpg',
      title: 'Long Term Sustainability',
    },
    {
      id: '02',
      img: 'https://res.cloudinary.com/dmozsgywz/image/upload/v1694361083/Solar-Power/about2_zohnri.jpg',
      title: 'High-end Performance',
    },
    {
      id: '03',
      img: 'https://res.cloudinary.com/dmozsgywz/image/upload/v1700122304/Solar-Power/about3_rth9u7.jpg',
      title: 'World Class Customer Service',
    },
  ];
  return (
    <>
      <VStack gap={'30px'}>
        <VStack
          height={{ base: '250px', md: '400px', lg: '500px' }}
          width={'100%'}
          position={'relative'}
          justifyContent={'center'}
          p="6"
        >
          <Image
            zIndex={'-1'}
            position={'absolute'}
            src="https://res.cloudinary.com/dmozsgywz/image/upload/v1694361081/Solar-Power/about_zfpy6l.jpg"
            height={'100%'}
            width={'100%'}
            // borderRadius={['5px', '20px']}
            filter={'auto'}
            brightness={'60%'}
          />
          {/* <Heading
            color={'white'}
            children="About Us"
            position={'absolute'}
            top={'15%'}
            left={['30%', '7%']}
          /> */}

          <VStack width={{ base: '90%', md: '80%', lg: '70%' }}>
            <Heading
              children={'Empowering a Sustainable Future with Solar Energy'}
              color={'white'}
              fontSize={{ base: '24px', md: '28px', lg: '30px' }}
            />
            <Text
              color={'white'}
              textAlign={'center'}
              display={{ base: 'none', md: 'none', lg: 'block' }}
            >
              Jain Shree Solar: Your premier source for high-quality solar
              equipment in Indore, ujjain and nationwide. Explore our website
              for all your construction and industrial needs. Join us in
              harnessing clean energy for a brighter future.
            </Text>
          </VStack>
        </VStack>
        <Text
          display={{ base: 'block', md: 'block', lg: 'none' }}
          boxShadow="base"
          p="6"
          rounded="md"
          bg="white"
        >
          Jain Shree Solar: Your premier source for high-quality solar equipment
          in Indore, ujjain and nationwide. Explore our website for all your
          construction and industrial needs. Join us in harnessing clean energy
          for a brighter future.
        </Text>

        <Stack
          width={{ base: '100%', md: '98%', lg: '80%' }}
          gap={{ base: '10px', md: '20px', lg: '30px' }}
          direction={{ base: 'column', md: 'row' }}
          alignItems={'center'}
          // border={'2px solid red'}
        >
          <VStack
            width={'100%'}
            alignItems={'left'}
            gap={'30px'}
            padding={{ base: '20px', md: '10px', lg: '30px' }}
          >
            <Heading children="About Us" fontSize={'45px'} color={'green'} />
            <Text fontSize={'18px'}>
              With years of experience offering turnkey renewable energy
              solutions inIndore, Ujjain, Dhar, Dhamnod, Dhamnod or Barwani,
              etc. Jain Shree Solar integrates all aspects of solar to guarantee
              the best quality service and maintenance to our valued customers.
            </Text>
            <Text fontWeight={'medium'} fontSize={'18px'} color={'#001861'}>
              Don’t know where to start? We’ll show you how!
            </Text>
            <Link to={'/contact'}>
              <Button
                colorScheme="orange"
                width={'fit-content'}
                transform={'auto'}
                _hover={{ scale: '1.1' }}
              >
                Get a Quote
              </Button>
            </Link>
          </VStack>
          <VStack width={'100%'}>
            <VStack
              // padding={'10px'}
              position={'relative'}
              justifyContent={'end'}
              // align={'center'}
              // border={'2px solid red'}
              width={{ base: '300px', md: '350px', lg: '320px' }}
              height={{ base: '400px', md: '500px', lg: '500px' }}
            >
              <Image
                position={'absolute'}
                zIndex={'-1'}
                top={'0px'}
                src="https://res.cloudinary.com/dmozsgywz/image/upload/v1694605818/Solar-Power/profile_gnd62q.jpg"
                width={'100%'}
                height={'100%'}
              />
              <Text
                color={'white'}
                fontSize={'20px'}
                padding={'0px 5px'}
                // border={'2px solid red'}
                width={'100%'}
              >
                Harshit Jain
              </Text>
            </VStack>
          </VStack>
        </Stack>
        <Heading
          padding={'7px 15px'}
          children="Jain Shree Solar is underpinned by 3 key principles:"
          fontSize={'24px'}
        />
        <Grid
          gridTemplateColumns={['1fr', '1fr 1fr 1fr']}
          width={{ base: '100%', md: '100%', lg: '80%' }}
          padding={'5px 10px'}
          gap={'10px'}
          // border={'2px solid red'}
        >
          {aboutUs.map((item, index) => (
            <VStack>
              <Image src={item.img} height={'330px'} />
              <Heading
                textAlign={'center'}
                children={item.title}
                fontSize={'18px'}
              />
            </VStack>
          ))}
        </Grid>
      </VStack>
    </>
  );
};

export default About;
