import {
  Box,
  Grid,
  Heading,
  Image,
  ListItem,
  OrderedList,
  Stack,
  Text,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';

const Residential = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <VStack>
        <VStack
          height={{ base: '250px', md: '400px', lg: '500px' }}
          width={'100%'}
          position={'relative'}
          justifyContent={'center'}
          p="6"
        >
          <Image
            zIndex={'-1'}
            position={'absolute'}
            src="https://res.cloudinary.com/dmozsgywz/image/upload/v1694361071/Solar-Power/residential_knscez.jpg"
            height={'100%'}
            width={'100%'}
            // borderRadius={['5px', '20px']}
            filter={'auto'}
            brightness={'60%'}
          />
          {/* <Heading
            color={'white'}
            children="About Us"
            position={'absolute'}
            top={'15%'}
            left={['30%', '7%']}
          /> */}

          <VStack width={{ base: '90%', md: '80%', lg: '70%' }}>
            <Heading
              children={'Residential Solar Systems'}
              color={'white'}
              fontSize={{ base: '24px', md: '28px', lg: '30px' }}
            />
            <Text
              color={'white'}
              textAlign={'center'}
              display={{ base: 'none', md: 'none', lg: 'block' }}
            >
              Residential solar systems use panels to capture sunlight, powering
              homes sustainably and reducing energy costs. They're the modern
              way for homeowners to embrace eco-friendliness while enjoying
              long-term savings. It's a green investment that pays dividends for
              both your wallet and the planet.
            </Text>
          </VStack>
        </VStack>
        <Text
          display={{ base: 'block', md: 'block', lg: 'none' }}
          boxShadow="base"
          p="6"
          rounded="md"
          bg="white"
        >
          Residential solar systems use panels to capture sunlight, powering
          homes sustainably and reducing energy costs. They're the modern way
          for homeowners to embrace eco-friendliness while enjoying long-term
          savings. It's a green investment that pays dividends for both your
          wallet and the planet.
        </Text>
        <VStack
          width={'100%'}
          padding={{ base: '10px', md: '10px', lg: '20px' }}
          gap={'100px'}
          // border={'2px solid red'}
        >
          <Stack
            direction={{ base: 'column', md: 'row' }}
            gap={{ base: '10px', md: '15px', lg: '20px' }}
            padding={{ base: '5px', md: '10px', lg: '30px' }}
            // border={'2px solid green'}
            width={{ base: '100%', md: '100%', lg: '85%' }}
            justifyContent={'center'}
          >
            <VStack alignItems={'left'} gap={'20px'} width={'100%'}>
              <Heading
                children={'Residential Solar Systems'}
                color={'#001861'}
              />
              <Text fontSize={'18px'}>
                Many homeowners across the country are choosing to be more
                self-sufficient with their energy needs, and you can too!
              </Text>
              <Text fontSize={'18px'}>
                By installing a state-of-the-art custom solar system from Jain
                Shree Solar, you'll have all the energy your home requires while
                also helping the environment.
              </Text>
              <Text fontSize={'18px'}>
                With energy costs and inflation on the rise, saving money is
                getting tougher every day. Solar power can be the answer to
                significantly lowering your energy bills for years to come.
              </Text>
            </VStack>
            <VStack width={'100%'}>
              <Image
                src="https://res.cloudinary.com/dmozsgywz/image/upload/v1694361007/Solar-Power/residential1_asnrsz.jpg"
                height={'100%'}
              />
            </VStack>
          </Stack>
          <Stack
            direction={{ base: 'column', md: 'row-reverse' }}
            gap={{ base: '10px', md: '15px', lg: '20px' }}
            padding={{ base: '5px', md: '10px', lg: '30px' }}
            // border={'2px solid green'}
            width={{ base: '100%', md: '100%', lg: '85%' }}
            justifyContent={'center'}
          >
            <VStack alignItems={'left'} gap={'20px'} width={'100%'}>
              <Heading
                color={'#001861'}
                children={
                  'Choose Jain Shree Solar for Your Residential Solar Power'
                }
              />
              <Text fontSize={'18px'}>
                Solar power offers you a clean and sustainable energy solution,
                utilizing photovoltaic (PV) solar panels to fuel your home.
              </Text>
              <Text fontSize={'18px'}>
                It's not just about saving money on your electricity bills; it's
                also about increasing your home's value, making a positive
                impact on the environment, and contributing to the broader
                energy supply.
              </Text>
              <Text fontSize={'18px'}>
                Whether you're a homeowner or a home builder looking to tap into
                the expanding sustainable housing market, Jain Shree Solar is
                your go-to partner for hassle-free solar systems.
              </Text>
              <Text fontSize={'18px'}>
                Start harnessing solar power for your home in Indore, Ujjain,
                Dhar, Dhamnod, Dhamnod or Barwani, etc today!
              </Text>
            </VStack>
            <VStack width={'100%'}>
              <Image
                src="https://res.cloudinary.com/dmozsgywz/image/upload/v1694361007/Solar-Power/residential2_ewuqtz.jpg"
                height={'100%'}
              />
            </VStack>
          </Stack>
          <Stack
            direction={{ base: 'column', md: 'row' }}
            gap={{ base: '10px', md: '15px', lg: '20px' }}
            padding={{ base: '5px', md: '10px', lg: '30px' }}
            // border={'2px solid green'}
            width={{ base: '100%', md: '100%', lg: '85%' }}
            justifyContent={'center'}
          >
            <VStack alignItems={'left'} gap={'20px'} width={'100%'}>
              <Heading
                children={'How Many Solar Panels Do I Need?'}
                color={'#001861'}
              />
              <Text fontSize={'18px'}>
                The number of solar panels you need is determined by three main
                factors:
              </Text>
              <OrderedList spacing={'2'}>
                <ListItem fontSize={'18px'}>
                  Your household's electricity consumption.
                </ListItem>
                <ListItem fontSize={'18px'}>
                  The type and efficiency of the solar panels you select.
                </ListItem>
                <ListItem fontSize={'18px'}>
                  The amount of sunlight your property gets.
                </ListItem>
              </OrderedList>
              <Text>
                We will create a customized solution to perfectly match your
                needs using solar, providing you the best savings and return on
                your investment.
              </Text>
              <Heading
                color={'#001861'}
                fontSize={'17px'}
                children="How Long Do Residential Solar Systems Last?"
              />
              <Text>
                Jain Shree Solar systems are made of durable tempered glass and
                with a lifetime of up to 25 years or even more.
              </Text>
            </VStack>
            <VStack width={'100%'}>
              <Image
                src="https://res.cloudinary.com/dmozsgywz/image/upload/v1694361013/Solar-Power/residential3_brug3a.jpg"
                height={'100%'}
              />
            </VStack>
          </Stack>
          <Stack
            direction={{ base: 'column', md: 'row-reverse' }}
            gap={{ base: '10px', md: '15px', lg: '20px' }}
            padding={{ base: '5px', md: '10px', lg: '30px' }}
            // border={'2px solid green'}
            width={{ base: '100%', md: '100%', lg: '85%' }}
            justifyContent={'center'}
          >
            <VStack alignItems={'left'} gap={'20px'} width={'100%'}>
              <Heading
                color={'#001861'}
                children={'Roofing Service Before Solar System Installation'}
              />
              <Text fontSize={'18px'}>
                Wondering if your roof should be replaced before installing your
                new solar system? Jain Shree Solar has you covered.
              </Text>
              <Text fontSize={'18px'}>
                Our team of professional, licensed, and insured experts can
                install a brand-new roof that meets the highest weatherproofing
                standards while simultaneously providing your home with clean
                solar power.
              </Text>
              <Text fontSize={'18px'} fontWeight={'bold'}>
                Here are some signs to look out for that indicate you may need a
                new roof:
              </Text>
              <Text fontSize={'18px'}>
                Your roof is more than 10 years old.
              </Text>
              <UnorderedList>
                <ListItem>
                  The roof surface, shingles, or metal appear old, worn, or
                  rusted.
                </ListItem>
                <ListItem>You've experienced hail or wind damage.</ListItem>
                <ListItem>
                  Your roof is leaking (the most obvious sign).
                </ListItem>
                <ListItem>
                  Roofing materials are cracking, curling, or blistering.
                </ListItem>
                <ListItem>
                  Portions of the roof are missing, torn, or damaged.
                </ListItem>
                <ListItem>
                  You notice extensive granule loss on shingles.
                </ListItem>
                <ListItem>
                  Your roofing material is less energy-efficient than it should
                  be.
                </ListItem>
              </UnorderedList>
              <Text>
                Don't hesitate to reach out to Jain Shree Solar to discuss your
                roofing and solar needs!
              </Text>
            </VStack>
            <VStack width={'100%'}>
              <Image
                src="https://res.cloudinary.com/dmozsgywz/image/upload/v1694361012/Solar-Power/residential4_sct0kh.jpg"
                height={'100%'}
              />
            </VStack>
          </Stack>
        </VStack>
      </VStack>
    </>
  );
};

export default Residential;
