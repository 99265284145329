import {
  Box,
  Divider,
  Heading,
  HStack,
  Image,
  ListItem,
  Stack,
  Text,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import {
  TiSocialYoutubeCircular,
  TiSocialInstagramCircular,
  TiSocialFacebookCircular,
} from 'react-icons/ti';
import { DiGithub } from 'react-icons/di';
import { TiSocialFacebook } from 'react-icons/ti';
import { TiSocialTwitter } from 'react-icons/ti';
import { AiOutlineInstagram } from 'react-icons/ai';
import { FaLinkedinIn } from 'react-icons/fa';
import { ImGooglePlus } from 'react-icons/im';
import { FaWhatsapp } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Footer = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <VStack
        backgroundColor={'blackAlpha.100'}
        marginTop={['30px', '50px']}
        userSelect={'none'}
      >
        <Stack
          justifyContent={'space-between'}
          alignItems={'center'}
          width={'100%'}
          direction={['column', 'row']}
          padding={'20px'}
        >
          <Text fontSize={['18px', '20px']}>
            Get connected with us on social networks:
          </Text>
          <HStack gap={'25px'}>
            {/* <Link to={'https://www.facebook.com/'} target="_blank">
              <TiSocialFacebook fontSize={'24px'} color="#3b5998" />
            </Link> */}
            {/* <Link to={'https://twitter.com/'} target="_blank">
              <TiSocialTwitter fontSize={'24px'} color="#55acee" />
            </Link> */}
            <Link
              to={'https://www.instagram.com/jainshreesolar/'}
              target="_blank"
            >
              <AiOutlineInstagram fontSize={'24px'} color="#d62976" />
            </Link>
            <Link to={'https://wa.me/message/2XERHLDLMDIWA1'} target="_blank">
              <FaWhatsapp fontSize={'24px'} color="#43d854" />
            </Link>
            <Link
              to={'mailto:jainshreesolarsolution2019@gmail.com?Subject=Hello'}
              target="_blank"
            >
              <ImGooglePlus fontSize={'24px'} color="#dc4e41" />
            </Link>
          </HStack>
        </Stack>
        <Divider borderColor={'grey'} />
        <Stack
          width={'100%'}
          padding={'30px'}
          alignItems={'top'}
          gap={'30px'}
          direction={{ base: 'column', lg: 'row' }}
        >
          <VStack
            width={'100%'}
            alignItems={'left'}
            gap={'0px'}
            // border={'2px solid red'}
          >
            <Box className="logo">
              <Link to={'/'}>
                <Image
                  src="logo.png"
                  alt=""
                  srcset=""
                  width={{ base: '150px', md: '160px', lg: '200px' }}
                  // height={['40px', '60px']}
                  // margin={['0px 20px', '0px 0px']}

                  // border={'1px solid red'}
                />
              </Link>
            </Box>
            <Text width={['100%', '75%']}>
              Empowering Tomorrow with Solar Brilliance. Welcome to Jain Shree
              Solar, where we craft sustainable solutions that sparkle under the
              sun. Join us in illuminating a cleaner, brighter world.
            </Text>
          </VStack>
          <VStack
            width={'100%'}
            alignItems={'left'}
            paddingTop={{ base: '0px', md: '30px', lg: '30px' }}
          >
            <Heading
              children="USEFUL LINKS"
              fontSize={'20px'}
              fontWeight={'medium'}
            />
            <Link to={'/products'}>
              <Text>Products</Text>
            </Link>
            <Link to={'/whyus'}>
              <Text>Why Us</Text>
            </Link>
            <Link to={'/projects'}>
              <Text>Projects</Text>
            </Link>
            <Link to={'/about'}>
              <Text>About Us</Text>
            </Link>
          </VStack>
          <VStack
            width={'100%'}
            alignItems={'left'}
            userSelect={'text'}
            paddingTop={{ base: '0px', md: '30px', lg: '30px' }}
          >
            <Heading
              children="CONTACT"
              fontSize={'20px'}
              fontWeight={'medium'}
            />
            <Text>52/2 Ghatkarpar Marg , Freeganj, Ujjain (M.P)</Text>
            <Text>jainshreesolarsolution2019@gmail.com</Text>
            <Text>+91 782 850 2802</Text>
            {/* <Text>+ 01 234 567 89</Text> */}
          </VStack>
        </Stack>
        <VStack
          width={'100%'}
          backgroundColor={'blackAlpha.100'}
          padding={'25px'}
        >
          <Text>© 2023 Copyright: Jain Shree Solar Solutions</Text>
        </VStack>
      </VStack>
    </>
  );
};

export default Footer;
