import {
  Box,
  Button,
  ButtonGroup,
  FormLabel,
  Grid,
  HStack,
  Heading,
  Image,
  Input,
  Stack,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import { contactUs } from '../redux/actions/user';
import { AiFillPhone } from 'react-icons/ai';
import { ImLocation } from 'react-icons/im';
import { MdEmail } from 'react-icons/md';
import { FaGlobeAmericas } from 'react-icons/fa';
import { ImWhatsapp } from 'react-icons/im';
import { Link } from 'react-router-dom';
const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const contact = [
    {
      id: '01',
      title: 'Phone',
      content: '+91 782 850 2802',
      icon: <AiFillPhone />,
    },
    {
      id: '02',
      title: 'Email',
      content: 'jainshreesolarsolution2019@gmail.com',
      icon: <MdEmail />,
    },
    {
      id: '03',
      title: 'Address',
      content: '52/2 Ghatkarpar Marg , Freeganj,  Ujjain (M.P)',
      icon: <ImLocation />,
    },
  ];
  const dispatch = useDispatch();
  const {
    loading,
    message: stateMessage,
    error,
  } = useSelector(state => state.user);

  const submitHandler = e => {
    e.preventDefault();
    dispatch(contactUs(name, email, message, subject));
    setName('');
    setEmail('');
    setMessage('');
    setSubject('');
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch({ type: 'clearError' });
    }
    if (stateMessage) {
      toast.success(stateMessage);
      dispatch({ type: 'clearMessage' });
    }
  }, [dispatch, error, stateMessage]);
  return (
    <>
      <VStack gap={'30px'}>
        <VStack
          height={{ base: '250px', md: '400px', lg: '500px' }}
          width={'100%'}
          position={'relative'}
          justifyContent={'center'}
          p="6"
        >
          <Image
            zIndex={'-1'}
            position={'absolute'}
            src="https://res.cloudinary.com/dmozsgywz/image/upload/v1694361088/Solar-Power/contact_qbqaqt.jpg"
            height={'100%'}
            width={'100%'}
            // borderRadius={['5px', '20px']}
            filter={'auto'}
            brightness={'60%'}
          />
          {/* <Heading
            color={'white'}
            children="About Us"
            position={'absolute'}
            top={'15%'}
            left={['30%', '7%']}
          /> */}

          <VStack width={{ base: '90%', md: '80%', lg: '70%' }}>
            <Heading
              children={'Get in Touch Today!'}
              color={'white'}
              fontSize={{ base: '24px', md: '28px', lg: '30px' }}
            />
            <Text
              color={'white'}
              textAlign={'center'}
              display={['none', 'block']}
            >
              Have a question, suggestion, or just want to say hi? We'd love to
              hear from you! Drop us a message, and our team will be happy to
              assist you.
            </Text>
          </VStack>
        </VStack>
        <Text
          display={['block', 'none']}
          boxShadow="base"
          p="6"
          rounded="md"
          bg="white"
        >
          Have a question, suggestion, or just want to say hi? We'd love to hear
          from you! Drop us a message, and our team will be happy to assist you.
        </Text>
        <Stack
          gap={{ base: '15px', md: '30px', lg: '60px' }}
          direction={['column', 'row']}
          // border={'2px solid red'}
          alignItems={'center'}
          padding={'20px'}
          justifyContent={'center'}
          flexWrap={'wrap'}
        >
          {contact.map((item, index) => (
            <VStack key={index} width={'fit-content'}>
              <Box
                backgroundColor={'orangered'}
                padding={'10px'}
                borderRadius={'50%'}
                fontSize={'20px'}
                color={'white'}
              >
                {item.icon}
              </Box>
              <HStack alignItems={'baseline'}>
                <Heading children={`${item.title}:`} fontSize={'18px'} />
                <Text fontSize={'18px'}>{item.content}</Text>
              </HStack>
            </VStack>
          ))}
        </Stack>
        <Grid
          width={{ base: '95%', md: '98%', lg: '80%' }}
          gridTemplateColumns={{ base: '1fr', md: '5fr 4fr', lg: '7fr 3fr' }}
          // border={'2px solid green'}
          gap={{ base: '20px', md: '10px', lg: '30px' }}
        >
          <VStack
            alignItems={'left'}
            padding={['10px', '20px']}
            // border={'2px solid red'}
            paddingBottom={['30px', '50px']}
          >
            <Heading children="Message Us" fontSize={'30px'} color={'orange'} />
            <Text>
              Got questions or feedback? Reach out to us, and we'll get back to
              you ASAP!
            </Text>
            <form onSubmit={submitHandler} style={{ width: '100%' }}>
              <Box my={'4'}>
                <FormLabel htmlFor="name" children={'Name'} />
                <Input
                  required
                  id="name"
                  value={name}
                  onChange={e => setName(e.target.value)}
                  placeholder="Full Name"
                  type={'text'}
                  focusBorderColor="green.500"
                  backgroundColor={'white'}
                  height={'50px'}
                />
              </Box>
              <Box my={'4'}>
                <FormLabel htmlFor="email" children={'Email Address'} />
                <Input
                  required
                  id="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  placeholder="Email Address"
                  type={'email'}
                  focusBorderColor="green.500"
                  backgroundColor={'white'}
                  height={'50px'}
                />
              </Box>
              <Box my={'4'}>
                <FormLabel htmlFor="subject" children={'Subject'} />
                <Input
                  required
                  id="subject"
                  value={subject}
                  onChange={e => setSubject(e.target.value)}
                  placeholder="Subject"
                  type={'text'}
                  focusBorderColor="green.500"
                  backgroundColor={'white'}
                  height={'50px'}
                />
              </Box>
              <Box my={'4'}>
                <FormLabel htmlFor="message" children={'Message'} />
                <Textarea
                  required
                  id="message"
                  value={message}
                  onChange={e => setMessage(e.target.value)}
                  placeholder="Write your message here...."
                  focusBorderColor="green.500"
                  backgroundColor={'white'}
                  height={'100px'}
                />
              </Box>

              <ButtonGroup
                gap={'30px'}
                justifyContent={'space-around'}
                // border={'2px solid red'}
                width={'100%'}
              >
                <Button isLoading={loading} colorScheme={'green'} type="submit">
                  Send Message
                </Button>
                <Button colorScheme="whatsapp">
                  <Link
                    to={'https://wa.me/message/2XERHLDLMDIWA1'}
                    target="_blank"
                  >
                    <HStack>
                      <Text>Whatsapp</Text>
                      <ImWhatsapp />
                    </HStack>
                  </Link>
                </Button>
              </ButtonGroup>
            </form>
          </VStack>

          <VStack padding={'5px'} justifyContent={'center'}>
            <Image
              src="https://res.cloudinary.com/dmozsgywz/image/upload/v1694361078/Solar-Power/whatsapp_b9rixg.jpg"
              borderRadius={'10px'}
            />
          </VStack>
        </Grid>
      </VStack>
    </>
  );
};

export default Contact;
