import React, { useEffect, useState } from 'react';
import {
  Box,
  ButtonGroup,
  HStack,
  Heading,
  IconButton,
  Image,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { BsArrowLeft } from 'react-icons/bs';
import { BsArrowRight } from 'react-icons/bs';

const Project = () => {
  const { id } = useParams();
  const [count, setCount] = useState(0);
  const projects = [
    {
      title: 'Neemuch Industrial Area Solar Power Plant',
      location: 'Neemuch Industrial Area, Madhya Pradesh, India',
      overview:
        'Our team at Jain Shree Solar takes immense pride in showcasing one of our standout projects, the Neemuch Industrial Area Solar Power Plant. This industrial-scale solar installation, located in the heart of Madhya Pradesh, demonstrates our commitment to sustainable energy solutions.',
      scope: [
        {
          title: "Client's Vision",
          description:
            'Our client, a prominent industrial entity operating in the Neemuch Industrial Area, had a clear vision of reducing their carbon footprint and energy costs while ensuring uninterrupted power supply for their equipment.',
        },
        {
          title: 'Capacity',
          description:
            'The Neemuch Industrial Area Solar Power Plant boasts an impressive capacity of 400 kW, making it one of the largest solar installations in the region. ',
        },
        {
          title: 'Energy Production',
          description:
            "This solar power plant generates a staggering 2000 units of clean, renewable electricity per day. This substantial daily output ensures that our  client's industrial operations remain fully powered by solar energy, significantly reducing their dependence on conventional grid electricity.",
        },
      ],
      challenges: [
        {
          title: 'Customized Solution',
          description:
            'To meet the diverse energy demands of industrial equipment, our team designed a customized solar solution tailored to the specific requirements of the client.',
        },
        {
          title: 'Space Optimization',
          description:
            ' Given the limited available space in the industrial area, our engineers employed advanced solar panel placement techniques to maximize energy production.',
        },
        {
          title: 'Grid Integration',
          description:
            'Seamless integration with the local grid system was achieved to ensure consistent power supply even during periods of low solar irradiance.',
        },
      ],
      impacts: [
        'By generating 2000 units of clean energy daily, this solar power plant reduces greenhouse gas emissions and contributes significantly to a cleaner and greener environment.',
        "Over its operational lifetime, the Neemuch Industrial Area Solar Power Plant will save a substantial amount of CO2 emissions, equivalent to [insert CO2 savings metric], further enhancing the region's environmental sustainability",
      ],
      outcomes: [
        'Our client is now running all their industrial equipment solely on solar power, resulting in substantial cost savings and environmental benefits.',
        'The Neemuch Industrial Area Solar Power Plant serves as a benchmark for sustainable energy solutions in the region, inspiring other industries to follow suit.',
      ],
      conclusion:
        'The Neemuch Industrial Area Solar Power Plant exemplifies our expertise in delivering high-impact solar solutions to industrial clients. It showcases our dedication to reducing carbon emissions, enhancing energy efficiency, and promoting sustainable practices in the industrial sector. This project stands as a testament to our commitment to creating a brighter, cleaner future through renewable energy.',
      images: [
        'https://res.cloudinary.com/dmozsgywz/image/upload/v1694361079/Solar-Power/project-2-img-1_u1acnw.jpg',
        'https://res.cloudinary.com/dmozsgywz/image/upload/v1694361074/Solar-Power/project-2-img-2_fue5td.jpg',
        'https://res.cloudinary.com/dmozsgywz/image/upload/v1694361063/Solar-Power/project-2-img-3_oeqhpy.jpg',
      ],
    },
    {
      title:
        'Solar Water Heating Systems for Kanya Shiksha Parisar Girls Hostel',
      location:
        'The Kanya Shiksha Parisar Girls Hostel : Maheshwar, Barwani, Rajpur, and Sendhwa',
      overview:
        'We are delighted to present our successful implementation of solar water heating systems at the KanyaShikshaParisar Girls Hostel, benefiting students and staff alike. This project exemplifies our commitment to harnessing renewable energy for practical and sustainable applications.',
      scope: [
        {
          title: 'Location',
          description:
            'The KanyaShikshaParisar Girls Hostel is a government institution serving female students in multiple towns, including Maheshwar, Barwani, Rajpur, and Sendhwa.',
        },
        {
          title: 'Solar Water Heating Systems',
          description:
            "A total of 46 solar water heating systems were installed across these locations. Each system boasts an impressive daily capacity of 500 liters, ensuring an ample supply of hot water for the hostel's residents. ",
        },
        {
          title: 'Operational Hours',
          description:
            'The solar water heating systems provide hot water from 10 AM to 4 PM daily. To ensure uninterrupted access to hot water, each system is equipped with a 3 kW electric backup, complete with a thermostat for precise temperature control.',
        },
      ],
      challenges: [
        {
          title: 'Distributed Deployment',
          description:
            'Coordinating the installation of 46 solar water heating systems across multiple locations required meticulous planning and execution.',
        },
        {
          title: 'Energy Backup',
          description:
            'To guarantee hot water availability even during adverse weather conditions or after sunset, the incorporation of electric backup systems with thermostatic control was a crucial element of the project.',
        },
      ],
      impacts: [
        "The solar water heating systems significantly reduce the hostel's reliance on conventional energy sources, thus lowering electricity consumption and utility costs.",
        'By utilizing solar energy, the project contributes to reducing greenhouse gas emissions and supports the transition to a more sustainable and eco-friendly energy source.',
        "Access to consistent hot water enhances the living conditions for the hostel's residents, positively impacting their well-being and comfort.",
      ],
      outcomes: [
        'The implementation of these solar water heating systems has provided a reliable and sustainable source of hot water to the KanyaShikshaParisar Girls Hostel, meeting the daily needs of students and staff.',
        'This project serves as an exemplary model for leveraging renewable energy to meet the thermal energy demands of educational institutions and government facilities across multiple locations.',
      ],
      conclusion:
        "Our work in equipping the KanyaShikshaParisar Girls Hostel with solar water heating systems underscores our dedication to providing practical, environmentally responsible solutions. This project enhances the quality of life for the hostel's residents, promotes energy efficiency, and contributes to a greener future. We remain committed to pioneering sustainable energy solutions that make a meaningful impact on communities and the environment.",
      images: [
        'https://res.cloudinary.com/dmozsgywz/image/upload/v1694361061/Solar-Power/project-1-img-1_ofjngj.jpg',
        'https://res.cloudinary.com/dmozsgywz/image/upload/v1694361063/Solar-Power/project-1-img-2_hdlnoi.jpg',
        'https://res.cloudinary.com/dmozsgywz/image/upload/v1694361043/Solar-Power/project-1-img-3_gh4rxm.jpg',
        'https://res.cloudinary.com/dmozsgywz/image/upload/v1694361046/Solar-Power/project-1-img-4_jtadyc.jpg',
      ],
    },
    {
      title: 'Solar Water Heating Systems for Government Girls Hostel ',
      location: 'Government Girls Hostel - Dhar, Dhamnod, and Tirla Towns',
      overview:
        'We take great pride in showcasing yet another successful project, the installation of 46 solar water heating systems at the Government Girls Hostel in Dhar, Dhamnod, and Tirla towns. This project underscores our unwavering commitment to harnessing solar energy for practical and sustainable applications.',
      scope: [
        {
          title: 'Location',
          description:
            'The Government Girls Hostel spans multiple towns, including Dhar, Dhamnod, and Tirla.',
        },
        {
          title: 'Solar Water Heating Systems',
          description:
            "In total, we installed 46 solar water heating systems at this hostel. Each system is designed with a generous daily capacity of 500 liters, ensuring a consistent supply of hot water for the hostel's residents.",
        },
        {
          title: 'Operational Hours',
          description:
            'These solar water heating systems provide hot water from 10 AM to 4 PM daily. To guarantee uninterrupted access to hot water, each system is equipped with a 3 kW electric backup and a thermostat for precise temperature control.',
        },
      ],
      challenges: [
        {
          title: 'Multi-Town Deployment',
          description:
            'Coordinating the installation of 46 solar water heating systems across multiple towns required meticulous planning, logistics, and skilled personnel.',
        },
        {
          title: 'Energy Backup',
          description:
            'To ensure a continuous hot water supply regardless of weather conditions or time of day, our solution includes electric backup systems with thermostat controls.',
        },
      ],
      impacts: [
        "By harnessing solar energy, these systems significantly reduce the hostel's reliance on conventional energy sources, leading to reduced electricity consumption and utility expenses.",
        ' This project contributes to lowering greenhouse gas emissions and supports the transition toward a cleaner, more sustainable energy source.',
        "Consistent access to hot water enhances the living conditions for the hostel's residents, positively impacting their well-being and comfort.",
      ],
      outcomes: [
        'The installation of these solar water heating systems has provided a reliable and sustainable source of hot water to the Government Girls Hostel across Dhar, Dhamnod, and Tirla towns, meeting the daily needs of students and staff.',
        'This project serves as a shining example of how renewable energy can effectively meet the thermal energy demands of government facilities and educational institutions across multiple locations.',
      ],
      conclusion:
        "Our work in equipping the Government Girls Hostel with solar water heating systems reaffirms our commitment to providing practical, environmentally responsible solutions. This initiative enhances the quality of life for the hostel's residents, promotes energy efficiency, and contributes to a greener and more sustainable future. We remain dedicated to pioneering innovative energy solutions that make a meaningful impact on communities and the environment.",
      images: [
        'https://res.cloudinary.com/dmozsgywz/image/upload/v1694361064/Solar-Power/project-3-img-2_nhxdyt.jpg',
        'https://res.cloudinary.com/dmozsgywz/image/upload/v1694361070/Solar-Power/project-3-img-1_vk96hx.jpg',
      ],
    },
    {
      title: 'Sampat Farm Solar Power Generation Plant',
      location: 'Sampat Farm area of Indore, Madhya Pradesh, India.',
      overview:
        'We are delighted to present our Sampat Farm Solar Power Generation Plant project, a testament to our commitment to sustainable energy solutions. Located in the serene surroundings of Sampat Farm in Indore, Madhya Pradesh, this 5 kW solar power plant has been custom-designed to meet the diverse energy needs of our esteemed client.',
      scope: [
        {
          title: "Client's Vision",
          description:
            "Our client's vision was clear - to power essential household appliances like air conditioners, refrigerators, coolers, and washing machines using solar energy. This not only reduces electricity bills but also contributes to a greener and more sustainable lifestyle.",
        },
        {
          title: 'Location',
          description:
            'The Sampat Farm Solar Power Generation Plant is nestled in the picturesque Sampat Farm area of Indore, Madhya Pradesh, India.',
        },
        {
          title: 'Capacity',
          description:
            'With a capacity of 5 kW, this solar power plant harnesses the abundant sunlight in the region to generate clean and renewable electricity.',
        },
        {
          title: 'Energy Production',
          description:
            'The plant generates an impressive 600 units of electricity every month. This substantial monthly output powers various appliances, including air conditioners, refrigerators, coolers, and washing machines.',
        },
      ],
      challenges: [
        {
          title: 'Customized Solution',
          description:
            "To cater to the specific energy demands of the client's appliances, our engineers designed a bespoke solar power system that ensures seamless operation.",
        },
        {
          title: 'Optimal Location',
          description:
            'The selection of the Sampat Farm location was strategic, leveraging the ample sunlight available in the region for maximum energy generation.',
        },
      ],
      impacts: [
        'The Sampat Farm Solar Power Generation Plant significantly reduces reliance on fossil fuels and lowers greenhouse gas emissions, contributing to a cleaner environment.',
      ],
      outcomes: [
        'Our client can now run air conditioners, refrigerators, coolers, and washing machines entirely on solar power, leading to substantial cost savings and a reduced carbon footprint.',
        'The Sampat Farm Solar Power Generation Plant exemplifies our commitment to providing sustainable energy solutions for residential clients, enabling them to embrace cleaner and more eco-friendly energy sources.',
      ],
      conclusion:
        'The Sampat Farm Solar Power Generation Plant is a shining example of our dedication to providing tailored, eco-friendly energy solutions. It empowers our clients to lead more sustainable lives while enjoying the convenience of essential household appliances. We remain committed to pioneering innovative solar projects that contribute to a brighter, cleaner future for all.',
      images: [
        'https://res.cloudinary.com/dmozsgywz/image/upload/v1694361088/Solar-Power/project-4-img-1_fqq8rq.jpg',
      ],
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <VStack padding={'10px'} gap={'30px'}>
        <VStack
          width={{ base: '100%', md: '100%', lg: '80%' }}
          gap={'0px'}
          // border={'2px solid red'}
        >
          <VStack padding={'20px 0px'} alignItems={'left'}>
            <Heading children={projects[id - 1].title} />
            <Text fontSize={'15px'} opacity={'0.8'}>
              {projects[id - 1].location}
            </Text>
            <Text>{projects[id - 1].overview}</Text>
          </VStack>
          <VStack
            width={'100%'}
            height={{ base: '250px', md: '400px', lg: '500px' }}
            position={'relative'}
            justifyContent={'center'}
            // border={'2px solid green'}
          >
            <Image
              src={projects[id - 1].images[count]}
              width={'100%'}
              height={'100%'}
              zIndex={'-1'}
              position={'absolute'}
            />
            <HStack
              padding={'5px'}
              // border={'2px solid red'}
              justifyContent={'space-between'}
              width={'100%'}
            >
              <IconButton
                onClick={() => setCount(count === 0 ? count : count - 1)}
              >
                <span>
                  <BsArrowLeft />
                </span>
              </IconButton>
              <IconButton
                onClick={() =>
                  setCount(
                    count === projects[id - 1].images.length - 1
                      ? count
                      : count + 1
                  )
                }
              >
                <span>
                  <BsArrowRight />
                </span>
              </IconButton>
            </HStack>
          </VStack>
          <HStack
            // border={'2px solid blue'}
            // width={'100%'}
            // justifyContent={'center'}
            overflowX={'scroll'}
            gap={'0px'}
            css={{
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            }}
          >
            {projects[id - 1].images.map((img, index) => (
              <Image
                src={img}
                width={'120px'}
                height={'80px'}
                // border={'1px solid black'}
                transition={'all 0.2s ease'}
                shadow={'lg'}
                onClick={() => setCount(index)}
                cursor={'pointer'}
                filter={'auto'}
                brightness={`${count === index ? '30%' : '100%'}`}
                _hover={{
                  filter: 'auto',
                  brightness: '30%',
                }}
              />
            ))}
          </HStack>
        </VStack>
        <VStack
          width={{ base: '100%', md: '100%', lg: '80%' }}
          gap={'20px'}
          // border={'2px solid red'}
          padding={'10px'}
        >
          <Heading children="Project Scope" fontSize={'25px'} />
          {projects[id - 1].scope.map((item, index) => (
            <VStack alignItems={'left'} width={'100%'}>
              <Heading children={item.title} fontSize={'20px'} />
              <Text>{item.description}</Text>
            </VStack>
          ))}
          <Heading children="Challenges Overcome" fontSize={'25px'} />
          {projects[id - 1].challenges.map((item, index) => (
            <VStack alignItems={'left'} width={'100%'}>
              <Heading children={item.title} fontSize={'20px'} />
              <Text>{item.description}</Text>
            </VStack>
          ))}
          <Heading children="Project Outcomes" fontSize={'25px'} />
          {projects[id - 1].outcomes.map((item, index) => (
            <Text width={'100%'}>{item}</Text>
          ))}
          <Heading
            children="Environmental and Social Impact"
            fontSize={'25px'}
          />
          {projects[id - 1].impacts.map((item, index) => (
            <Text width={'100%'}>{item}</Text>
          ))}
          <Heading children="Conclusion" fontSize={'25px'} />

          <Text width={'100%'}>{projects[id - 1].conclusion}</Text>
        </VStack>
      </VStack>
    </>
  );
};

export default Project;
