import {
  Box,
  Grid,
  Heading,
  Image,
  ListItem,
  OrderedList,
  Stack,
  Text,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';

const Commercial = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <VStack>
        <VStack
          height={{ base: '200px', md: '350px', lg: '500px' }}
          width={'100%'}
          position={'relative'}
          justifyContent={'center'}
          p="6"
        >
          <Image
            zIndex={'-1'}
            position={'absolute'}
            src="https://res.cloudinary.com/dmozsgywz/image/upload/v1694361086/Solar-Power/commercial_aljsgw.jpg"
            height={'100%'}
            width={{ base: '98%', md: '96%', lg: '100%' }}
            // borderRadius={['5px', '20px']}
            filter={'auto'}
            brightness={'60%'}
          />
          {/* <Heading
            color={'white'}
            children="About Us"
            position={'absolute'}
            top={'15%'}
            left={['30%', '7%']}
          /> */}

          <VStack width={{ base: '90%', md: '80%', lg: '70%' }}>
            <Heading
              children={'Commercial Solar Systems'}
              color={'white'}
              fontSize={{ base: '24px', md: '28px', lg: '30px' }}
            />
            <Text
              color={'white'}
              textAlign={'center'}
              display={{ base: 'none', md: 'none', lg: 'block' }}
            >
              Commercial solar systems harness sunlight to generate electricity,
              saving businesses money and reducing their carbon footprint. They
              can be installed on rooftops or open land, providing sustainable
              energy solutions for companies.
            </Text>
          </VStack>
        </VStack>
        <Text
          display={{ base: 'block', md: 'block', lg: 'none' }}
          boxShadow="base"
          p="6"
          rounded="md"
          bg="white"
        >
          Commercial solar systems harness sunlight to generate electricity,
          saving businesses money and reducing their carbon footprint. They can
          be installed on rooftops or open land, providing sustainable energy
          solutions for companies.
        </Text>
        <VStack
          width={'100%'}
          padding={{ base: '10px', md: '10px', lg: '20px' }}
          gap={'100px'}
          // border={'2px solid red'}
        >
          <Stack
            direction={{ base: 'column', md: 'row' }}
            gap={{ base: '10px', md: '15px', lg: '20px' }}
            padding={{ base: '5px', md: '10px', lg: '30px' }}
            // border={'2px solid green'}
            width={{ base: '100%', md: '100%', lg: '85%' }}
            justifyContent={'center'}
          >
            <VStack alignItems={'left'} gap={'20px'} width={'100%'}>
              <Heading
                children={'Commercial Solar Systems'}
                color={'#001861'}
              />
              <Text fontSize={'18px'}>
                Businesses can't afford to wait, and neither can your energy
                expenses.
              </Text>
              <Text fontSize={'18px'}>
                As your company expands, Jain Shree Solar is here to assist you
                in making wise investments that yield the best returns and align
                with ESG (Environmental, Social, and Governance) standards. With
                our sustainable power generation solutions, you can save money
                and enhance your brand.
              </Text>
              <Text fontSize={'18px'}>
                We handle the entire process, allowing you to concentrate on
                your business endeavors. That's why companies across Indore,
                Ujjain, Dhar, Dhamnod, Dhamnod or Barwani, etc are choosing us
                for cost-effective, all-in-one solar systems. You'll receive a
                tailored solution that caters to your company's specific needs.
              </Text>
            </VStack>
            <VStack width={'100%'}>
              <Image
                src="https://res.cloudinary.com/dmozsgywz/image/upload/v1694361087/Solar-Power/commercial1_sq1dr3.jpg"
                height={'100%'}
              />
            </VStack>
          </Stack>
          <Stack
            direction={{ base: 'column', md: 'row-reverse' }}
            gap={{ base: '10px', md: '15px', lg: '20px' }}
            padding={{ base: '5px', md: '10px', lg: '30px' }}
            // border={'2px solid green'}
            width={{ base: '100%', md: '100%', lg: '85%' }}
            justifyContent={'center'}
          >
            <VStack alignItems={'left'} gap={'20px'} width={'100%'}>
              <Heading
                children={'How Does a Commercial Solar System Work?'}
                color={'#001861'}
              />
              <Text fontSize={'18px'}>
                Our systems are designed to offer distributed power, which means
                every kilowatt-hour generated by the solar panels is at your
                company's disposal, regardless of the number of buildings in
                your complex. Many of our commercial clients connect their grids
                to the local power grid to take advantage of net metering
                benefits.
              </Text>
              <Text fontSize={'18px'}>
                We offer various installation options, including:
              </Text>
              <UnorderedList>
                <ListItem>Roof arrays</ListItem>
                <ListItem>Carport installations</ListItem>
                <ListItem>Large ground-mounted solar panel arrays</ListItem>
              </UnorderedList>
              <Text>
                You can choose the option that best suits your company's space
                and energy needs.
              </Text>
            </VStack>
            <VStack width={'100%'}>
              <Image
                src="https://res.cloudinary.com/dmozsgywz/image/upload/v1694361087/Solar-Power/commercial2_srguoh.jpg"
                height={'100%'}
              />
            </VStack>
          </Stack>
          <Stack
            direction={{ base: 'column', md: 'row' }}
            gap={{ base: '10px', md: '15px', lg: '20px' }}
            padding={{ base: '5px', md: '10px', lg: '30px' }}
            // border={'2px solid green'}
            width={{ base: '100%', md: '100%', lg: '85%' }}
            justifyContent={'center'}
          >
            <VStack alignItems={'left'} gap={'20px'} width={'100%'}>
              <Heading
                children={'What Business Benefits Does Solar Power Provide?'}
                color={'#001861'}
              />
              <Text fontSize={'18px'}>
                Jain Shree Solar is the ideal choice for your business if you're
                looking to maximize your returns by investing in clean energy.
              </Text>
              <Text>
                You can take advantage of the federal Solar Investment Tax
                Credit (ITC) along with other state and federal incentives to
                make a significant dent in your expenses.
              </Text>
              <Text>
                Many states also offer credits and incentives to support your
                company's transition to solar power.
              </Text>
              <Heading
                fontSize={'20px'}
                children={'Store Extra Energy for a Rainy Day'}
              />
              <Text>
                Additionally, when you opt for Jain Shree Solar Commercial
                Solar, you have the option to install a battery array to store
                surplus power. This serves as a backup system for any unforeseen
                circumstances, such as power grid failures or natural disasters.
                You can even divert excess energy into the battery bank to
                ensure it's fully charged and ready for any worst-case
                scenarios.
              </Text>
              <Heading
                fontSize={'20px'}
                children={'Live Up to Your Eco-friendly Reputation'}
              />
              <Text>
                By investing in Jain Shree Solar power systems, you're not only
                reducing your carbon footprint but also demonstrating your
                commitment to responsible business practices. With ESG
                (Environmental, Social, and Governance) standards gaining
                prominence, you can show your clients and partners that you care
                about the environment and are dedicated to sustainable business
                practices.
              </Text>
            </VStack>
            <VStack width={'100%'}>
              <Image
                src="https://res.cloudinary.com/dmozsgywz/image/upload/v1694361089/Solar-Power/commercial3_pqfujm.jpg"
                height={'100%'}
              />
            </VStack>
          </Stack>
          <Stack
            direction={{ base: 'column', md: 'row-reverse' }}
            gap={{ base: '10px', md: '15px', lg: '20px' }}
            padding={{ base: '5px', md: '10px', lg: '30px' }}
            // border={'2px solid green'}
            width={{ base: '100%', md: '100%', lg: '85%' }}
            justifyContent={'center'}
          >
            <VStack alignItems={'left'} gap={'20px'} width={'100%'}>
              <Heading color={'#001861'} children={'Roofing Service'} />
              <Text fontSize={'18px'}>
                If you're concerned about the condition of your roof before
                installing a solar system, Jain Shree Solar is your solution.
              </Text>
              <Text fontSize={'18px'}>
                Our team of professional, licensed, and insured experts is here
                to not only install a new roof with top-notch weatherproofing
                standards but also to seamlessly integrate clean solar power for
                your home simultaneously. With Jain Shree Solar, you can ensure
                the durability and efficiency of your roof while harnessing the
                benefits of solar energy.
              </Text>
            </VStack>
            <VStack width={'100%'}>
              <Image
                src="https://res.cloudinary.com/dmozsgywz/image/upload/v1694361088/Solar-Power/commercial4_idxcma.jpg"
                height={'100%'}
              />
            </VStack>
          </Stack>
        </VStack>
      </VStack>
    </>
  );
};

export default Commercial;
