import {
  Box,
  Button,
  FormLabel,
  HStack,
  Heading,
  Input,
  VStack,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Text,
  ButtonGroup,
  Stack,
  IconButton,
  Grid,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { IoMdCall } from 'react-icons/io';
const Calculator = () => {
  const [unit, setUnit] = useState(null);
  const [solution, setSolution] = useState(0);
  const [power, setPower] = useState(0);
  const [pricing, setPricing] = useState(0);
  const clear = () => {
    setUnit('');
    setSolution(0);
    setPower(0);
    setPricing(0);
  };
  const calculate = e => {
    e.preventDefault();
    const count = 120;
    const total = unit / count;
    const value = Math.ceil(total);

    switch (true) {
      case value >= 1 && value <= 5:
        setSolution(value);
        setPower(value * 120);
        setPricing(value * 51000 + value * 51000 * 0.138);
        break;
      case value > 5 && value <= 10:
        setSolution(value);
        setPower(value * 120);
        setPricing(value * 50000 + value * 50000 * 0.138);
        break;
      case value > 10 && value <= 25:
        setSolution(value);
        setPower(value * 120);
        setPricing(value * 45000 + value * 45000 * 0.138);
        break;
      case value > 25 && value <= 50:
        setSolution(value);
        setPower(value * 120);
        setPricing(value * 43000 + value * 43000 * 0.138);
        break;
      case value > 50 && value <= 100:
        setSolution(value);
        setPower(value * 120);
        setPricing(value * 42000 + value * 42000 * 0.138);
        break;
      case value > 100 && value <= 200:
        setSolution(value);
        setPower(value * 120);
        setPricing(value * 41000 + value * 41000 * 0.138);
        break;
      case value > 200 && value <= 500:
        setSolution(value);
        setPower(value * 120);
        setPricing(value * 38000 + value * 38000 * 0.138);
        break;
      case value > 500:
        setSolution(value);
        setPower(value * 120);
        setPricing(value * 36000 + value * 36000 * 0.138);
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <VStack
        padding={{ base: '10px', md: '5px', lg: '50px' }}
        // border={'2px solid red'}
      >
        <Heading children="See Solar Cost" fontSize={'30px'} color={'orange'} />
        <VStack
          display={{ base: 'none', md: 'flex', lg: 'none' }}
          padding={'20px 30px'}
          justifyContent={'center'}
          backgroundColor={'#001861'}
          color={'white'}
          gap={'10px'}
          borderRadius={'20px'}
          width={'100%'}
        >
          <Heading children="Better Solar, Better Savings." fontSize={'26px'} />
          <Heading
            textAlign={'center'}
            children="Unlock your dreams with an affordable down payment!"
            fontSize={'26px'}
          />

          <Link to={'/contact'}>
            <Button
              colorScheme="orange"
              // margin={'10px 0px'}
              transform={'auto'}
              _hover={{ scale: '1.1' }}
            >
              Get a Quote
            </Button>
          </Link>

          <HStack>
            <Box>
              <IoMdCall fontSize={'30px'} />
            </Box>
            <Heading children="+91 782 850 2802" fontSize={'26px'} />
          </HStack>
        </VStack>
        <Grid
          width={'100%'}
          gridTemplateColumns={{
            base: '1fr',
            md: '1fr 1fr',
            lg: '1fr 1fr 1fr',
          }}
          padding={{ base: '10px', md: '20px', lg: '20px' }}
          gap={{ base: '20px', md: '5px', lg: '30px' }}
          // border={'2px solid green'}
          // alignItems={'center'}
        >
          <VStack
            display={{ base: 'flex', md: 'none', lg: 'flex' }}
            padding={'30px'}
            justifyContent={'center'}
            backgroundColor={'#001861'}
            color={'white'}
            gap={'20px'}
            borderRadius={'20px'}
            alignItems={'left'}
          >
            <Heading
              children="Better Solar, Better Savings."
              fontSize={'26px'}
            />
            <Heading
              children="Unlock your dreams with an affordable down payment!"
              fontSize={'26px'}
            />

            <Link to={'/contact'}>
              <Button
                colorScheme="orange"
                margin={'10px 0px'}
                transform={'auto'}
                _hover={{ scale: '1.1' }}
              >
                Get a Quote
              </Button>
            </Link>

            <HStack>
              <Box>
                <IoMdCall fontSize={'30px'} />
              </Box>
              <Heading children="+91 782 850 2802" fontSize={'26px'} />
            </HStack>
          </VStack>

          <VStack alignItems={'left'} gap={'30px'}>
            <form onSubmit={calculate}>
              <VStack justifyContent={'center'} alignItems={'left'}>
                <HStack>
                  <Heading
                    children="1kW = "
                    fontSize={'18px'}
                    // color={'green'}
                  />
                  <Text fontSize={'18px'}>1000w</Text>
                </HStack>
                <HStack>
                  <Heading
                    children="1kW : "
                    fontSize={'18px'}
                    // color={'green'}
                  />
                  <Text fontSize={'18px'}>4 units per day</Text>
                </HStack>

                <HStack my={'3'}>
                  <FormLabel
                    htmlFor="unit"
                    children={'Monthly Units'}
                    color={'red'}
                    width={'100px'}
                  />
                  <Input
                    required
                    id="unit"
                    value={unit}
                    onChange={e => setUnit(e.target.value)}
                    placeholder="e.g - 1200"
                    type={'number'}
                    focusBorderColor="green.500"
                    backgroundColor={'white'}
                    height={'45px'}
                    width={'150px'}
                  />
                </HStack>
                <HStack
                  // border={'2px solid red'}
                  gap={'20px'}
                  justifyContent={'left'}
                >
                  <Button colorScheme={'green'} type="submit">
                    Calculate
                  </Button>
                  <Button colorScheme={'green'} onClick={clear}>
                    Clear
                  </Button>
                </HStack>
              </VStack>
            </form>
            <VStack alignItems={'left'} gap={'20px'}>
              <HStack>
                <Heading
                  children="Proposed Solution : "
                  fontSize={'18px'}
                  color={'green'}
                />
                <Text fontSize={'18px'}>{solution}kW</Text>
              </HStack>
              <HStack>
                <Heading
                  children="Expected Power Generation : "
                  fontSize={'18px'}
                  color={'green'}
                />
                <Text fontSize={'18px'}>{power} units</Text>
              </HStack>
              <HStack>
                <Heading
                  children="Expected Pricing : "
                  fontSize={'18px'}
                  color={'green'}
                />
                <Text fontSize={'18px'}>{pricing}/-</Text>
              </HStack>
            </VStack>
          </VStack>

          <TableContainer>
            <Table variant="simple" size="sm">
              {/* <TableCaption>Imperial to metric conversion factors</TableCaption> */}
              <Thead>
                <Tr>
                  <Th>Solar Power Units</Th>
                  <Th>Price</Th>
                  {/* <Th isNumeric>multiply by</Th> */}
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>1kW-5kW</Td>
                  <Td>51000 + 13.8% Tax</Td>
                </Tr>
                <Tr>
                  <Td>6kW-10kW</Td>
                  <Td>50000 + 13.8% Tax</Td>
                </Tr>
                <Tr>
                  <Td>11kW-25kW</Td>
                  <Td>45000 + 13.8% Tax</Td>
                </Tr>
                <Tr>
                  <Td>26kW-50kW</Td>
                  <Td>43000 + 13.8% Tax</Td>
                </Tr>
                <Tr>
                  <Td>51kW-100kW</Td>
                  <Td>42000 + 13.8% Tax</Td>
                </Tr>
                <Tr>
                  <Td>101kW-200kW</Td>
                  <Td>41000 + 13.8% Tax</Td>
                </Tr>
                <Tr>
                  <Td>201kW-500kW</Td>
                  <Td>38000 + 13.8% Tax</Td>
                </Tr>
                <Tr>
                  <Td>501kW-1MW</Td>
                  <Td>36000 + 13.8% Tax</Td>
                </Tr>
              </Tbody>
              <Tfoot>
                <Tr>
                  <Th>In watt</Th>
                  <Th>In Rupees</Th>
                </Tr>
              </Tfoot>
            </Table>
          </TableContainer>
        </Grid>
      </VStack>
    </>
  );
};

export default Calculator;
