import {
  Box,
  Button,
  Grid,
  HStack,
  Heading,
  Image,
  ListItem,
  Stack,
  Text,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import './style.css';
const WhySolar = () => {
  const features = [
    {
      id: '01',
      title: 'Qualification',
      description:
        'Request a free consultation with our experts to assess your solar energy eligibility, calculate potential savings, and tailor a solution to your needs.',
      img: 'https://res.cloudinary.com/dmozsgywz/image/upload/v1700126255/Solar-Power/feature1_jnnqun.jpg',
    },
    {
      id: '02',
      title: 'Design',
      description:
        'Our in-house engineering team will craft a custom solar solution, optimizing output to maximize your savings while maintaining cost efficiency.',
      img: 'https://res.cloudinary.com/dmozsgywz/image/upload/v1700126254/Solar-Power/feature2_kzllws.jpg',
    },
    {
      id: '03',
      title: 'Permitting',
      description:
        "Our team will handle all permits and inspections, ensuring compliance with your township's regulations and guaranteeing a safe and punctual installation every time.",
      img: 'https://res.cloudinary.com/dmozsgywz/image/upload/v1700124564/Solar-Power/feature3_pi3p3l.jpg',
    },
    {
      id: '04',
      title: 'Installation',
      description:
        'Our expertly trained and professionally licensed solar technicians will install your system, conduct thorough testing, and ensure all monitoring tools are fully operational.',
      img: 'https://res.cloudinary.com/dmozsgywz/image/upload/v1700126255/Solar-Power/feature4_wcq06s.jpg',
    },
    {
      id: '05',
      title: 'Results',
      description:
        "Now, you can enjoy significant savings while also contributing to a greener planet. Jain Shree Solar's solution creates a true win-win scenario for you and the environment.",
      img: 'https://res.cloudinary.com/dmozsgywz/image/upload/v1700126255/Solar-Power/feature5_cadb7k.jpg',
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <VStack gap={'30px'}>
        <VStack
          height={{ base: '250px', md: '400px', lg: '500px' }}
          width={'100%'}
          position={'relative'}
          justifyContent={'center'}
          p="6"
        >
          <Image
            zIndex={'-1'}
            position={'absolute'}
            src="https://res.cloudinary.com/dmozsgywz/image/upload/v1694361080/Solar-Power/why_oqvbit.jpg"
            height={'100%'}
            width={'100%'}
            // borderRadius={['5px', '20px']}
            filter={'auto'}
            brightness={'60%'}
          />

          <VStack width={{ base: '90%', md: '80%', lg: '70%' }}>
            <Heading
              children={
                'Embrace a Brighter Future: The Advantages of Solar Energy'
              }
              color={'white'}
              fontSize={{ base: '24px', md: '28px', lg: '30px' }}
            />
            <Text
              color={'white'}
              textAlign={'center'}
              display={{ base: 'none', md: 'none', lg: 'block' }}
            >
              Embrace the sun's gift: go solar! Save on bills, ride the
              renewable wave, boost your home's value, and be an eco-hero. Take
              control, enjoy incentives, and let low-maintenance panels power
              your world with sunshine for years to come.
            </Text>
          </VStack>
        </VStack>
        <Text
          display={{ base: 'block', md: 'block', lg: 'none' }}
          boxShadow="base"
          p="6"
          rounded="md"
          bg="white"
        >
          Embrace the sun's gift: go solar! Save on bills, ride the renewable
          wave, boost your home's value, and be an eco-hero. Take control, enjoy
          incentives, and let low-maintenance panels power your world with
          sunshine for years to come.
        </Text>
        <VStack
          width={'100%'}
          padding={{ base: '10px', md: '20px', lg: '20px' }}
          // border={'2px solid red'}
        >
          <Heading
            children="Benefits of Going Solar"
            fontSize={'30px'}
            color={'orange'}
            width={'100%'}
            padding={['5px', '0px 30px']}
          />
          <Grid
            gridTemplateColumns={{ base: '1fr', md: '1fr', lg: '1fr 1fr' }}
            gap={{ base: '20px', md: '20px', lg: '20px' }}
            padding={{ base: '5px', md: '10px', lg: '30px' }}
            // border={'2px solid green'}
            width={{ base: '100%', md: '100%', lg: '85%' }}
            justifyContent={'center'}
          >
            <VStack alignItems={'left'} gap={'10px'}>
              <UnorderedList>
                <ListItem fontSize={'20px'} fontWeight={'medium'}>
                  Advantages of Solar
                </ListItem>
              </UnorderedList>
              <Text>
                Solar energy is the cleanest and most abundant renewable source
                available today. It reduces energy costs, benefits the
                environment, and boosts your home's value. Join Jain Shree Solar
                for this transformative journey towards a greener future.
              </Text>
              <UnorderedList>
                <ListItem fontSize={'20px'} fontWeight={'medium'}>
                  Reduce Your Energy and Maintenance Costs
                </ListItem>
              </UnorderedList>
              <Text>
                Select Jain Shree Solar to escape rising utility expenses. Our
                solar setups lead to energy independence, customized to match
                your electricity needs. With 24/7 monitoring and warranties up
                to 25 years, bid farewell to escalating bills and welcome
                sustainable energy.
              </Text>
              <UnorderedList>
                <ListItem fontSize={'20px'} fontWeight={'medium'}>
                  Gain Tax Credits & Incentives
                </ListItem>
              </UnorderedList>
              <Text>
                Jain Shree Solar offers financing solutions. We're also here to
                assist you in taking full advantage of the Incentive Tax Credit,
                sales tax exemptions, rebate opportunities, and Renewable Energy
                Certificates. Get in touch with us to discover the incentives
                you qualify for.
              </Text>
              <UnorderedList>
                <ListItem fontSize={'20px'} fontWeight={'medium'}>
                  Solar Works and Here’s Why
                </ListItem>
              </UnorderedList>
              <Text>
                Our systems are more practical than you can imagine, offering
                reliability and cost-effectiveness like never before. They can
                be adapted to virtually any property and incorporate the latest
                materials and technologies to ensure longevity and
                dependability. We collaborate with industry leaders and global
                suppliers to deliver optimal results for our valued clients.
              </Text>
            </VStack>
            <VStack>
              <Image
                src="https://res.cloudinary.com/dmozsgywz/image/upload/v1694361084/Solar-Power/benifits_frwdkd.jpg"
                height={'100%'}
                float={'right'}
              />
            </VStack>
          </Grid>
        </VStack>
        <VStack padding={'20px'}>
          <Heading
            children="Solar Energy - From Start to Finish"
            fontSize={'30px'}
            color={'orange'}
          />
          <Grid
            gridTemplateColumns={{
              base: '1fr',
              md: '1fr 1fr 1fr',
              lg: '1fr  1fr 1fr 1fr 1fr',
            }}
            width={{ base: '100%', md: '100%', lg: '90%' }}
            padding={'20px 0px'}
            // border={'2px solid green'}
            // margin={'30px'}
          >
            {features.map((item, index) => (
              <VStack
                // border={'2px solid blue'}
                height={'400px'}
                position={'relative'}
                className="feature"
                alignItems={'center'}
                padding={'30px'}
                userSelect={'none'}
                // justifyContent={'center'}
              >
                <Image
                  className="featureImg"
                  src={item.img}
                  width={'100%'}
                  height={'100%'}
                  position={'absolute'}
                  zIndex={'-1'}
                  top={'0px'}
                />
                <VStack gap={'0px'}>
                  <Heading
                    children={item.title}
                    fontSize={'20px'}
                    color={'white'}
                    className="featureHeading"
                  />
                  <Text
                    display={{ lg: 'none' }}
                    color={'white'}
                    fontSize={'15px'}
                    opacity={'0.5'}
                    className="featurePress"
                  >
                    Click
                  </Text>
                </VStack>
                <Text
                  color={'whiteAlpha.900'}
                  textAlign={'center'}
                  className="featureText"
                >
                  {item.description}
                </Text>
              </VStack>
            ))}
          </Grid>
        </VStack>
      </VStack>
    </>
  );
};

export default WhySolar;
