import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Navbar from './components/Navbar';
import NotFound from './components/NotFound';
import About from './components/About';
import Contact from './components/Contact';
import Products from './components/Products';
import WhySolar from './components/WhySolar';
import Footer from './components/Footer';
import Projects from './components/Projects';
import { Toaster } from 'react-hot-toast';
import Menubar from './components/Menubar';
import Project from './components/Project';
import Residential from './components/Residential';
import Commercial from './components/Commercial';

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/products" element={<Products />} />
        <Route path="/whyus" element={<WhySolar />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/residential" element={<Residential />} />
        <Route path="/commercial" element={<Commercial />} />

        <Route path="/project/:id" element={<Project />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
      <Menubar />
      <Toaster />
    </BrowserRouter>
  );
}

export default App;
