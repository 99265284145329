import { useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
// import { ReactComponent as Hamburger } from '../../assets/icons/hamburger.svg';
// import { ReactComponent as Brand } from '../../assets/icons/logo.svg';
import { GiHamburgerMenu } from 'react-icons/gi';
import { ImCross } from 'react-icons/im';
import './navbar.css';
import { Box, Button, Image, ListItem, UnorderedList } from '@chakra-ui/react';
import { CloseIcon, HamburgerIcon, SmallCloseIcon } from '@chakra-ui/icons';

const Navbar = () => {
  const [showNavbar, setShowNavbar] = useState(false);
  const navigate = useNavigate();

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  return (
    <nav
      className={'navbarcolor'}
      style={{
        position: 'sticky',
        top: '0px',
        zIndex: '1000',
        padding: '0px 30px',
        userSelect: 'none',
      }}
    >
      <Box
        className="container"
        padding={{ base: '0px', md: '0px 0px', lg: '0px 5px', xl: '0px 25px' }}
      >
        <Box className="logo">
          <Link to={'/'} onClick={() => setShowNavbar(false)}>
            <Image
              src="logo.png"
              alt=""
              srcset=""
              width={{ base: '130px', md: '150px', lg: '170px', xl: '200px' }}
            />
          </Link>
        </Box>

        <div
          className="menu-icon"
          onClick={handleShowNavbar}
          style={{ userSelect: 'none' }}
        >
          {!showNavbar ? (
            <HamburgerIcon fontSize={'20px'} />
          ) : (
            <CloseIcon fontSize={'16px'} />
          )}
        </div>
        <div className={`nav-elements  ${showNavbar && 'active'}`}>
          <UnorderedList alignItems={['', 'center']}>
            {/* <ListItem>
              <NavLink to="/" onClick={() => setShowNavbar(false)}>
                Home
              </NavLink>
            </ListItem> */}
            <ListItem>
              <NavLink to="/whyus" onClick={() => setShowNavbar(false)}>
                Why Us?
              </NavLink>
            </ListItem>
            <ListItem>
              <NavLink to="/residential" onClick={() => setShowNavbar(false)}>
                Residential Solar
              </NavLink>
            </ListItem>
            <ListItem>
              <NavLink to="/commercial" onClick={() => setShowNavbar(false)}>
                Commercial Solar
              </NavLink>
            </ListItem>

            <ListItem>
              <NavLink to="/projects" onClick={() => setShowNavbar(false)}>
                Projects
              </NavLink>
            </ListItem>
            <ListItem>
              <NavLink to="/products" onClick={() => setShowNavbar(false)}>
                Products
              </NavLink>
            </ListItem>
            <ListItem>
              <NavLink to="/about" onClick={() => setShowNavbar(false)}>
                About
              </NavLink>
            </ListItem>

            {/* <ListItem>
              <NavLink to="/services" onClick={() => setShowNavbar(false)}>
                Services
              </NavLink>
            </ListItem> */}
            <ListItem>
              <NavLink to="/contact" onClick={() => setShowNavbar(false)}>
                Contact
              </NavLink>
            </ListItem>
          </UnorderedList>
        </div>
      </Box>
    </nav>
  );
};

export default Navbar;
