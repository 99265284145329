import { Box, HStack, Heading, Image, Text, VStack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { AiFillPhone } from 'react-icons/ai';
import { ImLocation } from 'react-icons/im';
import { MdEmail } from 'react-icons/md';
import { IoMdCall } from 'react-icons/io';
import { GoMail } from 'react-icons/go';
import { ImWhatsapp } from 'react-icons/im';
import { SiGmail } from 'react-icons/si';
import { Link } from 'react-router-dom';

const Menubar = () => {
  const contact = [
    {
      id: '01',
      title: 'Phone',
      content: '+1235 2355 98',
      icon: <AiFillPhone />,
    },
    {
      id: '02',
      title: 'Email',
      content: 'info@yoursite.com',
      icon: <MdEmail />,
    },
    {
      id: '03',
      title: 'Address',
      content: '198 West 21th Street, Suite 721 New York NY 10016',
      icon: <ImLocation />,
    },
  ];
  const [bottom, setBottom] = useState(0);
  //   const showMenubar = () => {
  //     if (window.scroll) {
  //       setShow(false);
  //     } else {
  //       setShow(true);
  //     }
  //   };
  var prevScrollpos = window.pageYOffset;
  window.onscroll = function () {
    var currentScrollPos = window.pageYOffset;
    if (prevScrollpos > currentScrollPos) {
      setBottom(0);
    } else {
      setBottom(-100);
    }
    prevScrollpos = currentScrollPos;
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <HStack
        // border={'2px solid red'}
        width={'100%'}
        backgroundColor={'white'}
        // borderTop={'2px solid red'}
        position={'sticky'}
        bottom={bottom}
        transition={'all 0.4s ease-in-out'}
        display={{ base: 'flex', md: 'flex', lg: 'none' }}
        fontSize={'25px'}
        color={'blue'}
        justifyContent={'space-around'}
        padding={'5px'}
        userSelect={'none'}
      >
        <Box transform={'auto'} _hover={{ scale: '1.1' }}>
          <Link to={'tel:7828502802'} target="_blank">
            <VStack gap={'0px'}>
              <Image
                src="https://res.cloudinary.com/dmozsgywz/image/upload/v1694361091/Solar-Power/phone-menu_quz7kt.png"
                width={{ base: '30px', md: '48px' }}
              />
              <Text fontSize={'13px'} color={'black'}>
                Call Us
              </Text>
            </VStack>
          </Link>
        </Box>
        <Box transform={'auto'} _hover={{ scale: '1.1' }}>
          <Link to={'https://wa.me/message/2XERHLDLMDIWA1'} target="_blank">
            <VStack gap={'0px'}>
              <Image
                src="https://res.cloudinary.com/dmozsgywz/image/upload/v1694361078/Solar-Power/whatsapp-menu_fay1jn.png"
                width={{ base: '30px', md: '48px' }}
              />
              <Text fontSize={'13px'} color={'black'}>
                WhatsApp Us
              </Text>
            </VStack>
          </Link>
        </Box>
        <Box transform={'auto'} _hover={{ scale: '1.1' }}>
          <Link
            to={'mailto:jainshreesolarsolution2019@gmail.com?Subject=Hello'}
            target="_blank"
          >
            <VStack gap={'0px'}>
              <Image
                src="https://res.cloudinary.com/dmozsgywz/image/upload/v1694361090/Solar-Power/gmail-menu_n2n4yg.png"
                width={{ base: '30px', md: '48px' }}
              />
              <Text fontSize={'13px'} color={'black'}>
                Mail Us
              </Text>
            </VStack>
          </Link>
        </Box>
      </HStack>
    </>
  );
};

export default Menubar;
