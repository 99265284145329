import {
  Box,
  Grid,
  HStack,
  Heading,
  Image,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';

const Products = () => {
  const products = [
    {
      id: '01',
      title: 'Solar PV Power Plant',
      description:
        "When selecting a vendor for your solar rooftop, it's crucial to consider factors like top-quality products, industry-leading installation techniques, regular maintenance, and hassle-free installation. We offer all of this along with flexible financing options, including loans and direct purchase. Don't hesitate to reach out; we're here to assist you in making the optimal product choices for your business.",
      img: 'https://res.cloudinary.com/dmozsgywz/image/upload/v1694361091/Solar-Power/product1_rfs1ql.jpg',
    },
    {
      id: '02',
      title: 'Solar Water Heater',
      description:
        "Our Solar Water Heaters are among our top-selling products. They arrive fully equipped with guaranteed parts, ready for immediate use upon purchase. Clients value our commitment to providing only the highest quality products, meticulously chosen by our team. If you're unsure about the right choice for you, don't hesitate to call. Our experts will address all your doubts and guide you to the perfect solution.",
      img: 'https://res.cloudinary.com/dmozsgywz/image/upload/v1694361101/Solar-Power/product2_hks95r.jpg',
    },
    {
      id: '03',
      title: 'Solar Inverters ',
      description:
        "Our Solar Inverters are among our best-selling products, complete with guaranteed parts and ready for immediate use upon purchase. Clients value our commitment to providing only top-quality, carefully selected products. If you're unsure which one suits your needs, feel free to call us, and our experts will help address all your questions and find the perfect solution for you.",
      img: 'https://res.cloudinary.com/dmozsgywz/image/upload/v1700121270/Solar-Power/solar_inverter_pvfuab.jpg',
    },
    {
      id: '04',
      title: 'Solar BOS kit',
      description:
        "Our Solar BOS Kits, ranging from 50 kW to 1 MW, are the finest you can find in the market. Besides their top-notch quality, Sunkom Power provides unbeatable prices. If you come across a better price elsewhere, inform us, and we'll strive to match it. We're dedicated to helping you make the best product choices for your business, so please don't hesitate to reach out.",
      img: 'https://res.cloudinary.com/dmozsgywz/image/upload/v1700121779/Solar-Power/solar_boss_kit_fnvwo4.jpg',
    },
    {
      id: '05',
      title: 'Solar Street Lights',
      description:
        "Our Solar Street Lights are among our top-selling products, arriving complete with guaranteed parts and ready for immediate use upon purchase. Clients truly value the top-quality selection by our team. If you're uncertain about the right choice for your needs, don't hesitate to give us a call. Our experts are here to help address all your questions and guide you to the perfect solution.",
      img: 'https://res.cloudinary.com/dmozsgywz/image/upload/v1694361005/Solar-Power/product5_tsbv6j.webp',
    },
    {
      id: '06',
      title: 'Solar Irrigation Pump',
      description:
        "Our Solar Irrigation Pumps are the market's finest, renowned for their top quality. Sunkom Power takes pride in providing unbeatable prices. If you come across a better price elsewhere, inform us, and we'll make every effort to match it. We're committed to assisting you in making the optimal product choices for your business, so please don't hesitate to reach out.",
      img: 'https://res.cloudinary.com/dmozsgywz/image/upload/v1694361033/Solar-Power/product6_nppege.jpg',
    },
    {
      id: '07',
      title: 'Solar Panels',
      description:
        'Our Solar Panels are all top-of-the-line, ensuring guaranteed performance. These are among our best-selling products, serving customers for various applications. If you require assistance in selecting the right Solar Panel for a specific project or task, please reach out, and our consultants will gladly assist you.',
      img: 'https://res.cloudinary.com/dmozsgywz/image/upload/v1694361017/Solar-Power/product7_yk3egr.jpg',
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <VStack>
        <VStack
          height={{ base: '250px', md: '400px', lg: '500px' }}
          width={'100%'}
          position={'relative'}
          justifyContent={'center'}
          p="6"
        >
          <Image
            zIndex={'-1'}
            position={'absolute'}
            src="https://res.cloudinary.com/dmozsgywz/image/upload/v1694361024/Solar-Power/products_psy6lz.jpg"
            height={'100%'}
            width={'100%'}
            // borderRadius={['5px', '20px']}
            filter={'auto'}
            brightness={'60%'}
          />
          {/* <Heading
            color={'white'}
            children="About Us"
            position={'absolute'}
            top={'15%'}
            left={['30%', '7%']}
          /> */}

          <VStack width={{ base: '90%', md: '80%', lg: '70%' }}>
            <Heading
              children={'Exploring Diverse Solar Energy Products'}
              color={'white'}
              fontSize={{ base: '24px', md: '28px', lg: '30px' }}
            />
            <Text
              color={'white'}
              textAlign={'center'}
              display={{ base: 'none', md: 'none', lg: 'block' }}
            >
              Over the years, we've diligently obtained essential certifications
              that align with our Jain Shree Solar pillars, guaranteeing
              compliance with industry safety standards. These certificates,
              including "On Time or Your Money Back" and "Locally Owned,"
              exemplify our commitment to quality and safety in everything we
              undertake.
            </Text>
          </VStack>
        </VStack>
        <Text
          display={{ base: 'block', md: 'block', lg: 'none' }}
          boxShadow="base"
          p="6"
          rounded="md"
          bg="white"
        >
          Over the years, we've diligently obtained essential certifications
          that align with our Jain Shree Solar pillars, guaranteeing compliance
          with industry safety standards. These certificates, including "On Time
          or Your Money Back" and "Locally Owned," exemplify our commitment to
          quality and safety in everything we undertake.
        </Text>
        <VStack
          // alignItems={'left'}
          width={'100%'}
          // border={'2px solid red'}
          padding={{ base: '10px', md: '5px', lg: '20px' }}
        >
          <Heading
            children="Solar Products List"
            fontSize={'30px'}
            color={'orange'}
            width={'100%'}
            padding={['5px', '0px 30px']}
          />
          <Grid
            // width={'100%'}
            gridTemplateColumns={['1fr', '1fr']}
            // border={'2px solid green'}
            // margin={'30px'}
            gap={['20px', '30px']}
            padding={{ base: '5px', md: '10px', lg: '30px' }}
          >
            {products.map((item, index) => (
              <Stack
                // direction={['column-reverse', 'row']}
                direction={{ base: 'column-reverse', md: 'row' }}
                id={index}
                boxShadow={'base'}
                padding={['10px', '20px']}
                // backgroundColor={'whiteAlpha.900'}
                borderRadius={'20px'}
                width={'100%'}
                alignItems={'center'}
                position={'relative'}
                gap={'30px'}
                // border={'2px solid red'}
              >
                <VStack padding={'0px 7px'} width={'100%'}>
                  <Heading children={item.title} fontSize={'24px'} />
                  <Text>{item.description}</Text>
                </VStack>
                <Box width={'100%'}>
                  <Image
                    src={item.img}
                    height={{ base: '250px', md: '270px', lg: '350px' }}
                    width={'100%'}
                    borderRadius={'20px'}
                  />
                </Box>
              </Stack>
            ))}
          </Grid>
        </VStack>
      </VStack>
    </>
  );
};

export default Products;
